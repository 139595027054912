import api from "./api";
export const getInboundReport = async (data) => {
  return await api.get("/api/cc/reports/inbound_report", { params: data });
};
export const getInboundForwardingReport = async (data) => {
  let payload = { ...data };
  for (const key in payload) {
    if (payload[key] === null) {
      payload[key] = "";
    }
  }
  return await api.post("/api/cc/dids/call_forwarding_report", payload);
};

export const getDIDDrd = async () => {
  return await api.get("/api/cc/dids/get_lead_dids_and_did_groups");
};
export const getLeadSourceDrd = async (data) => {
  return await api.get("/api/lead_source/getDrd",{params:data});
};

