import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BiMessageRoundedAdd } from "react-icons/bi";
import { BsArrowLeftShort } from "react-icons/bs";
import { AiOutlineBell } from "react-icons/ai";
import dayjs from "dayjs";
import {
  get_message_users_list,
  setNewMessCurrentPage,
  smsCenterReset,
  setMessCurrentPage,
} from "../../../features/smsCenterSlice.js";
const NewMessage = ({
  setIsCustom,
  newMsg,
  setNewMsg,
  msgType,
  userType,
  setUserType,
  setNewMessArray,
  setChatArray,
  setIsEnd,
  setUserPhone,
  setUserId,
  setMultiArray,
  setChatFilter,
  handleDefatulData,
  socketMessages,
  reloadCurrentChat,
  chatFilter,
  setUserPhones,
  setChatCurrentPage,
  setIsLoadChat,
  setIsChatEnd,
  clearChatConversation,
  setRole,
}) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { record } = useSelector((state) => state.roles);
  const [selectedRole, setSelectedRole] = useState("");
  const dropdownRef = useRef(null);
  const handleChange = (e, type) => {
    setNewMessArray([]);
    setSelectedRole(e.target.value);
    setRole(e.target.value);
    handleNewMessUsersData(type, e.target.value);
  };

  const handleNewMessUsersData = async (userTypeNew, roleId = selectedRole) => {
    setUserId(null);
    let filter = {};
    setIsEnd(false);
    if (userTypeNew === "Vendor") {
      filter = { user_type: "Vendor" };
    } else if (userTypeNew === "Client") {
      filter = { user_type: "Client" };
    } else if (userTypeNew === "User" && roleId) {
      filter = { user_type: "User", role_id: roleId };
    }

    try {
      const res = await dispatch(
        get_message_users_list({ page: 1, ...filter })
      );

      if (res?.payload?.status === 200) {
        const data = res?.payload?.data?.data;
        if (data?.length) {
          await dispatch(setNewMessCurrentPage(2));
          setNewMessArray(data);
        }
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const truncateText = (text, limit) => {
    return text.length > limit ? text.substring(0, limit) + "..." : text;
  };

  const handleConversation = (data) => {
    dispatch(setChatCurrentPage(1));
    setUserId({
      ...data,
      user_type: data?.user_id?._id
        ? "User"
        : data?.client_id?._id
        ? "Client"
        : data?.vendor_id?._id
        ? "Vendor"
        : "Custom",
    });
    setUserPhone(data?.user_phone);
    setIsLoadChat(false);
    setChatArray([]);
    setIsChatEnd(false);

    clearChatConversation();
  };

  return (
    <div className="flex flex-row items-center w-full border-y border-1 bg-[#fff] p-[6px] pl-3.7 !pr-4">
      <ul className="flex flex-row items-center w-full justify-between !mb-0 !pl-0">
        {!newMsg ? (
          <>
            <li
              className="text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer flex items-center hover:bg-[#2d4665] hover:text-white  font-medium"
              onClick={() => {
                setNewMsg(true);
                setUserType("Client");
                handleNewMessUsersData("Client");
                setChatArray([]);
                setUserPhone("");
                //setNewMessArray([]);
                dispatch(setNewMessCurrentPage(2));
              }}
            >
              <BiMessageRoundedAdd
                size={24}
                className="cursor-pointer mr-1"
                onClick={() => {
                  setNewMsg(false);
                  setUserPhone("");
                }}
              />
              New Message
            </li>
            <div className="flex flex-row">
              <li
                className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                  msgType === "Chats"
                    ? "border-b-2 border-primary-100 text-primary-100"
                    : ""
                }`}
              >
                {"Chats"}
              </li>
              {socketMessages && socketMessages?.length ? (
                <li
                  className="py-2 px-2 relative border-transparent text-gray-800 rounded-full hover:text-gray-400 focus:outline-none focus:text-gray-500 transition duration-150 ease-in-out"
                  aria-label="Notifications"
                >
                  <AiOutlineBell className="h-6 w-6" />
                  <span
                    className="absolute top-0 right-0 translate-x-1 -translate-y-1 cursor-pointer"
                    onClick={handleClick}
                  >
                    <div
                      className={`inline-flex items-center justify-center px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-2 bg-red-500 text-white ${
                        socketMessages.length > 1 ? "px-2 py-1 text-sm" : ""
                      }`}
                      style={{
                        width: socketMessages.length > 1 ? "auto" : "22px",
                        height: socketMessages.length > 1 ? "auto" : "22px",
                      }}
                    >
                      {socketMessages.length}
                    </div>
                  </span>
                  {isOpen && (
                    <div
                      ref={dropdownRef}
                      className="absolute right-0 mt-2 w-64 max-h-96 overflow-y-auto rounded-sm shadow-lg border z-50 bg-white scrollbar-custom"
                    >
                      {socketMessages?.map((notification, index) => (
                        <div
                          key={index}
                          onClick={() => handleConversation(notification)}
                          className={` flex items-center p-2 bg-orange-50 rounded-sm border-b mx-auto max-w-xs relative m-1 px-2 py-2 cursor-pointer z-50 ${
                            index === socketMessages.length - 1
                              ? "last:border-b-0"
                              : ""
                          }`}
                        >
                          <span className="text-xs font-semibold uppercase m-1 py-0.5 mr-2 text-gray-500 absolute bottom-0 right-0">
                            {dayjs(notification?.createdAt).format("h:mm A")}
                          </span>

                          <div className="ml-3">
                            <h4 className="text-sm font-semibold leading-tight text-gray-900">
                              {notification.user_phone} |{" "}
                              {notification.user_type}
                            </h4>
                            <p className="text-xs text-gray-600 mt-1">
                              {truncateText(notification.message, 25)}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </li>
              ) : null}
            </div>
          </>
        ) : (
          <div
            className="flex flex-row items-center"
            onClick={() => {
              setMultiArray([]);
              dispatch(smsCenterReset());
              setChatFilter("all");
              dispatch(setMessCurrentPage(2));
              handleDefatulData("all");
              setUserPhone("");
            }}
          >
            <BsArrowLeftShort
              size={24}
              color="#2d4665"
              className="cursor-pointer"
              onClick={() => setNewMsg(false)}
            />
            <span className="font-semibold text-xs cursor-pointer">
              New Chat
            </span>
          </div>
        )}
        <div className="flex flex-row">
          {newMsg && (
            <>
              <select
                onChange={(e) => {
                  setUserType("User");
                  handleChange(e, "User");
                  setChatArray([]);
                  setUserPhone("");
                  //setNewMessArray([]);
                  dispatch(setNewMessCurrentPage(1));
                }}
                className="ml-2 bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 block w-24 h-8 p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-2 dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="" disabled selected>
                  Role
                </option>
                {record?.map((role, index) => {
                  return (
                    <option key={role._id} value={role._id}>
                      {role.name}
                    </option>
                  );
                })}
              </select>

              <li
                className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                  userType === "Client"
                    ? "border-b-2 border-primary-100 text-primary-100"
                    : ""
                }`}
                onClick={() => {
                  setUserType("Client");
                  handleNewMessUsersData("Client");
                  setChatArray([]);
                  setUserPhone("");
                  dispatch(setNewMessCurrentPage(2));
                }}
              >
                {"Client"}
              </li>
              <li
                className={`text-[#2d4665] py-[6px] px-[6px] text-xs cursor-pointer ${
                  userType === "Custom"
                    ? "border-b-2 border-primary-100 text-primary-100"
                    : ""
                }`}
                onClick={() => {
                  setUserType("Custom");
                  setIsCustom(true);
                  setUserPhone("");
                  dispatch(setNewMessCurrentPage(2));
                }}
              >
                {"Custom"}
              </li>
            </>
          )}
        </div>
      </ul>
    </div>
  );
};

export default NewMessage;
