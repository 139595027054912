const FormRadioGroup = ({
  name,
  label,
  formik,
  options = [],
  valueProp,
  labelProp,
  convertor,
  isHorizontal,
  isBorder,
  customStyle,
  isCheck,
  isOnChange,
  isFloat,
  errors,
  disabled,
  readOnly,
  className,
  isFloatClass = "",
}) => {
  return (
    <div className={` ${customStyle}`}>
      {label && (
        <label
          htmlFor={name}
          className={[
            `small`,
            isFloat
              ? `text-[rgba(0,_0,_0,_0.6)] absolute left-[0] top-0 font-normal text-base truncate max-w-[calc(133%-24px)] translate-x- -translate-y-[12px] ${isFloatClass} scale-75 px-1`
              : "",
          ].join(" ")}
          style={{
            background: "linear-gradient(0deg, white 50%, transparent 50%)",
          }}
        >
          {label}
        </label>
      )}

      <div
        className={`pl-2 p-1 ${
          isHorizontal ? "flex flex-col md:flex-row gap-x-4 md:flex-wrap" : ""
        }
        ${isBorder ? "!border !border-[#a9a9a9] rounded md:h-10" : ""} 
        ${
          formik?.errors[name] && formik?.touched[name]
            ? "bg-[#ff000005] text-red border-[red]"
            : ""
        },
        ${className}
        `}
      >
        {options.length > 0 &&
          options.map((option) => {
            return (
              <div
                key={option[valueProp]}
                className={`cursor-pointer md:self-center flex flex-row md:item-center`}
              >
                <input
                  name={name}
                  id={`${name}-${option[valueProp]}`}
                  type={"radio"}
                  checked={
                    formik
                      ? formik.values[name] === option[valueProp]
                      : isCheck === option[valueProp]
                  }
                  value={option[valueProp]}
                  onChange={({ target: { value } }) => {
                    if (formik) {
                      if (convertor) {
                        value = convertor(value);
                      }
                      formik?.setFieldValue(name, value);
                    } else if (isOnChange) {
                      isOnChange(value);
                    }
                  }}
                  readOnly={readOnly}
                  disabled={disabled}
                  className={`h-3.7 self-center`}
                />
                <label
                  className="medium pl-1 w-full"
                  htmlFor={`${name}-${option[valueProp]}`}
                >
                  {option[labelProp]}
                </label>
              </div>
            );
          })}
      </div>
      {formik?.errors[name] && formik?.touched[name] && (
        <small className="form-text text-muted">{formik?.errors[name]}</small>
      )}
      {errors !== undefined &&
        errors?.length > 0 &&
        errors?.map((error, index) => {
          return (
            <>
              {error[name] && (
                <small
                  style={{
                    fontSize: 12,
                    marginTop: -5,
                    paddingLeft: 16,
                    color: "#D32F2F",
                  }}
                  key={index}
                >
                  {error[name]
                    .replaceAll(`"${name}"`, label)
                    .replaceAll(`${name}`, label)}
                </small>
              )}
            </>
          );
        })}
    </div>
  );
};

export default FormRadioGroup;
