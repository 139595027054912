import React, { useState } from "react";
import { useSelector } from "react-redux";
import { sendIframeMsg } from "../../../util/iFrame";
import Button from "../../atoms/Button";
import { toast } from "react-toastify";
function DialerHeader() {
  const {
    status,
    timer,
    classColor,
    phone_numberD,
    callTypeD,
    did_descriptionD,
    inGroupD,
  } = useSelector((state) => state.dialer);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const [inQueue, setInQueue] = useState("0/0");
  const [isPrivateCalls, setIsPrivateCalls] = useState(false);
  const [listInQueue, setListInQueue] = useState([]);
  const [showInQueueList, setShowInQueueList] = useState(false);
  const callinqueuegrab = (id) => {
    if (status !== "PAUSE")
      return toast.info("Agent must be on pause to grab this call");
    sendIframeMsg({
      action: "callinqueuegrab",
      user: user?.dialer_user_info?.dialer_user,
      pass: user?.dialer_user_info?.dialer_pass,
      value: id,
    });
    setListInQueue([]);
    setShowInQueueList(false);
  };

  const receiveMessage = (event) => {
    if (event?.data?.action === "calls_in_queue") {
      setInQueue(event?.data?.value);
    }
    if (event?.data?.action === "callsinqueuelist") {
      if (isPrivateCalls) {
        setListInQueue(
          event?.data?.data?.filter(
            (item) => +item.agent_only === +user.dialer_user_info.dialer_user
          )
        );
      } else {
        setListInQueue(event?.data?.data);
      }
    }
  };
  window.addEventListener("message", receiveMessage);
  return (
    <div className="relative bg-dialer_bar h-[59px] hidden md:flex items-center py-2 px-2 w-auto rounded-t-md transition-all ease-in-ot duration-300">
      {showInQueueList ? (
        <div
          className={`min-w-[546px] ease-in-out duration-300 z-30 top-full p-3 origin-top-left absolute right !mt- w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5 left-0 -translate-x-[50%]
    ${
      showInQueueList
        ? "visible opacity-100 w-full translate-y-0"
        : "invisible opacity-0 w-0 -translate-y-full"
    }
    `}
        >
          <div className="flex justify-between">
            <h3 className="text-lg font-bold !mb-2 text-left font-pop">
              CALLS IN QUEUE:
            </h3>
            <Button
              text="X"
              className="text-xs rounded-sm mb-2"
              onClick={() => {
                sendIframeMsg({
                  action: "show_calls_in_queue",
                  user: user?.dialer_user_info?.dialer_user,
                  pass: user?.dialer_user_info?.dialer_pass,
                  value: "HIDE",
                });
                setListInQueue([]);
                setShowInQueueList(false);
              }}
              variant="btn_danger"
            />
          </div>

          <div className="container-fluid p-0">
            <div className="table-responsive">
              <table className="table table-bordered bg-light border-secondary">
                <thead className="table_header">
                  <tr>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Wait</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {listInQueue?.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className={`${
                          +user?.dialer_user_info?.dialer_user ===
                          +item?.agent_only
                            ? "bg-blue-100 text-white"
                            : ""
                        }`}
                      >
                        <td>{item?.phone_number}</td>
                        <td>{item?.wait}</td>
                        <td
                          className="hover:underline cursor-pointer text-blue-500"
                          onClick={() => callinqueuegrab(item.call_id)}
                        >
                          Take Call
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : null}

      <div className="flex flex-row gap-2">
        <div className="flex flex-col items-center justify-center w-auto">
          {status && (
            <span
              className={[
                "text-sm font-semibold min-w-[110px] w-auto text-center rounded flex flex-row justify-center items-center px-1.5 py-1 h-auto",
                classColor,
              ].join(" ")}
            >
              {status === "WAIT" ? "WAITING" : status}
            </span>
          )}
          {timer && (
            <span className="text-xs text-center text-[#8D8D8D] font-medium w-[110px]">
              Timer: {timer}
            </span>
          )}
        </div>
        <div className="flex flex-row items-cente justify-center w-auto gap-x-2">
          <button
            className={`bg-menu rounded text-white font-semibold px-2 h-[28px] text-xs flex items-center justify-center ${
              (status === "DISPO" ||
                status === "INCALL" ||
                status === "DEAD") &&
              callTypeD
                ? "mt-1.5"
                : ""
            }`}
            onClick={() => {
              sendIframeMsg({
                action: "show_calls_in_queue",
                user: user?.dialer_user_info?.dialer_user,
                pass: user?.dialer_user_info?.dialer_pass,
                value: "SHOW",
              });
              setIsPrivateCalls(false);
              setShowInQueueList(true);
            }}
          >
            {`Show Queue Calls (${inQueue?.split("/")[0]})`}
          </button>
          {+inQueue?.split("/")[1] > 0 && (
            <button
              className={`bg-red-600 rounded text-white font-semibold px-2 h-[28px] text-xs flex items-center justify-center ${
                (status === "DISPO" ||
                  status === "INCALL" ||
                  status === "DEAD") &&
                callTypeD
                  ? "mt-1.5"
                  : ""
              }`}
              onClick={() => {
                sendIframeMsg({
                  action: "show_calls_in_queue",
                  user: user?.dialer_user_info?.dialer_user,
                  pass: user?.dialer_user_info?.dialer_pass,
                  value: "SHOW",
                });
                setIsPrivateCalls(true);
                setShowInQueueList(true);
              }}
            >
              {`Private Calls (${inQueue?.split("/")[1]})`}
            </button>
          )}
        </div>
        {(status === "DISPO" || status === "INCALL" || status === "DEAD") &&
        callTypeD ? (
          <div className="flex flex-col items-cente justify-center w-auto text-white bg-[#747E84] !px-2 py-1 rounded">
            {callTypeD && (
              <>
                <small className="text-xs font-bold">
                  {callTypeD ? callTypeD : ""}:{" "}
                  <small className="ml-2 text-xs">
                    {phone_numberD ? phone_numberD : ""}
                  </small>
                </small>
                <small className="text-xs font-bold">
                  InGroup:{" "}
                  <small className="ml-2 text-xs">
                    {inGroupD ? inGroupD : ""}
                  </small>
                </small>
                <small className="text-xs font-bold">
                  GroupID:{" "}
                  <small className="ml-2 text-xs">
                    {did_descriptionD ? did_descriptionD : ""}
                  </small>
                </small>
              </>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default DialerHeader;
