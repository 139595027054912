import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createCalendarEvents,
  createGoogleCalendarEvents,
  deleteCalendarEvents,
  getCalendarEvents,
  getGoogleCalendarEvents,
  updateCalendarEvents,
  updateGoogleCalendarEvents,
} from "../services/calendarEvent";
import dayjs from "dayjs";
import { toast } from "react-toastify";
const customLabels = [
  {
    label: "Reminder",
    color: "indigo",
    checked: true,
  },
  {
    label: "Note",
    color: "purple",
    checked: true,
  },
  {
    label: "Other",
    color: "green",
    checked: true,
  },
];

const initialState = {
  calendarEvents: [],
  monthIndex: dayjs().month(),
  smallCalendarMonth: null,
  daySelected: dayjs(),
  showEventModal: false,
  selectedEvent: null,
  labels: customLabels,
  filteredEvents: [],
  showJobModal: false,
  selectedMoreJobs: [],
  filters: {
    industry_id: "",
    job_status_id: "",
  },
  addUpdateEvent: false,
  viewType: "day",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
export const get_calendar_events = createAsyncThunk(
  "get_calendar_events",
  async (data, thunkAPI) => {
    try {
      return await getCalendarEvents(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_google_calendar_events = createAsyncThunk(
  "get_google_calendar_events",
  async (data, thunkAPI) => {
    try {
      return await getGoogleCalendarEvents(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const create_calendar_events = createAsyncThunk(
  "create_calendar_events",
  async (data, thunkAPI) => {
    try {
      return await createCalendarEvents(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const update_calendar_events = createAsyncThunk(
  "update_calendar_events",
  async (data, thunkAPI) => {
    try {
      return await updateCalendarEvents(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const create_google_calendar_events = createAsyncThunk(
  "create_google_calendar_events",
  async (data, thunkAPI) => {
    try {
      return await createGoogleCalendarEvents(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const update_google_calendar_events = createAsyncThunk(
  "update_google_calendar_events",
  async (data, thunkAPI) => {
    try {
      return await updateGoogleCalendarEvents(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const delete_calendar_events = createAsyncThunk(
  "delete_calendar_events",
  async (data, thunkAPI) => {
    try {
      return await deleteCalendarEvents(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const calendarEventsSlice = createSlice({
  name: "calendarEvents",
  initialState,
  reducers: {
    setMonthIndex: (state, action) => {
      state.monthIndex = action.payload;
    },
    setViewType: (state, action) => {
      state.viewType = action.payload;
    },
    setSmallCalendarMonth: (state, action) => {
      state.smallCalendarMonth = action.payload;
    },
    setDaySelected: (state, action) => {
      state.daySelected = action.payload;
    },
    setShowEventModal: (state, action) => {
      state.showEventModal = action.payload;
      /* if(!action.payload){
        state.selectedEvent = null;
      } */
    },
    setSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
    setLabels: (state, action) => {
      state.labels = action.payload;
    },
    updateLabel: (state, action) => {
      state.labels = state.labels.map((lbl) =>
        lbl.label === action.payload.label ? action.payload : lbl
      );
    },
    setShowJobModal: (state, action) => {
      state.showJobModal = action.payload;
    },
    setSelectedMoreJobs: (state, action) => {
      state.selectedMoreJobs = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setAddUpdateEvent: (state, action) => {
      state.addUpdateEvent = action.payload;
      if (!action.payload) {
        state.selectedEvent = null;
      }
    },
    setIsEditingRecord: (state, action) => {
      state.isEditingRecord = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(get_calendar_events.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_calendar_events.fulfilled, (state, action) => {
        state.isLoading = false;
        state.calendarEvents = action.payload.data;
      })
      .addCase(get_calendar_events.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_google_calendar_events.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_google_calendar_events.fulfilled, (state, action) => {
        state.isLoading = false;
        state.calendarEvents = action.payload.data;
      })
      .addCase(get_google_calendar_events.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(create_calendar_events.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_calendar_events.fulfilled, (state) => {
        state.isLoading = false;
        state.addUpdateEvent = false;
        toast.success("Event added successfully");
      })
      .addCase(create_calendar_events.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload || "Event couldn't be added");
      })

      .addCase(update_calendar_events.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_calendar_events.fulfilled, (state) => {
        state.isLoading = false;
        state.addUpdateEvent = false;
        state.selectedEvent = null;
        toast.success("Event updated successfully");
      })
      .addCase(update_calendar_events.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload || "Event couldn't be updated");
      })

      .addCase(delete_calendar_events.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_calendar_events.fulfilled, (state) => {
        state.isLoading = false;
        state.showEventModal = false;
        state.selectedEvent = null;
      })
      .addCase(delete_calendar_events.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_google_calendar_events.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_google_calendar_events.fulfilled, (state) => {
        state.isLoading = false;
        state.addUpdateEvent = false;
        toast.success("Event added successfully");
      })
      .addCase(create_google_calendar_events.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload || "Event couldn't be added");
      })

      .addCase(update_google_calendar_events.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_google_calendar_events.fulfilled, (state) => {
        state.isLoading = false;
        state.addUpdateEvent = false;
        state.selectedEvent = null;
        toast.success("Event updated successfully");
      })
      .addCase(update_google_calendar_events.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload || "Event couldn't be updated");
      });
  },
});

export const {
  setMonthIndex,
  setViewType,
  setSmallCalendarMonth,
  setDaySelected,
  setShowEventModal,
  setSelectedEvent,
  setLabels,
  updateLabel,
  setShowJobModal,
  setSelectedMoreJobs,
  setFilters,
  setAddUpdateEvent,
  setIsEditingRecord,
} = calendarEventsSlice.actions;
export default calendarEventsSlice.reducer;
