import api from "./api";

export const getLeadSourceGroup = async (data) => {
  return await api.get(`/api/lead_source/group`, data);
};

export const getLeadSourceGroupDrd = async (data) => {
  return await api.get(`/api/lead_source/group/drd`, data);
};
export const createLeadSourceGroup = async (data) => {
  return await api.post("/api/lead_source/group", data);
};
export const updateLeadSourceGroup = async ({ _id, name }) => {
  return await api.put(`/api/lead_source/group/${_id}`, { name });
};
export const deleteLeadSourceGroup = async (id) => {
  return await api.delete(`/api/lead_source/group/${id}`);
};
