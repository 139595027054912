import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createLeadSourceProvider,
  deleteLeadSourceProvider,
  getLeadSourceProvider,
  getLeadSourceProviderDrd,
  updateLeadSourceProvider,
} from "../services/leadSourceProviderService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  providerDrd: [],
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Lead Source
export const get_lead_source_provider = createAsyncThunk(
  "get_lead_source_provider",
  async (thunkAPI) => {
    try {
      return await getLeadSourceProvider();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Lead Source
export const get_lead_source_provider_drd = createAsyncThunk(
  "get_lead_source_provider_drd",
  async (thunkAPI) => {
    try {
      return await getLeadSourceProviderDrd();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Lead Source
export const create_lead_source_provider = createAsyncThunk(
  "create_lead_source_provider",
  async (data, thunkAPI) => {
    try {
      return await createLeadSourceProvider(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Lead Source
export const update_lead_source_provider = createAsyncThunk(
  "update_lead_source_provider",
  async (data, thunkAPI) => {
    try {
      return await updateLeadSourceProvider(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Lead Source
export const delete_lead_source_provider = createAsyncThunk(
  "delete_lead_source_provider",
  async (id, thunkAPI) => {
    try {
      return await deleteLeadSourceProvider(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const leadSourceProviderSlice = createSlice({
  name: "lead_source_provider",
  initialState,
  reducers: {
    leadSourceProviderReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_lead_source_provider.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(get_lead_source_provider.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_lead_source_provider.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_lead_source_provider_drd.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(get_lead_source_provider_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.providerDrd = action.payload?.data;
      })
      .addCase(get_lead_source_provider_drd.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_lead_source_provider.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(create_lead_source_provider.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record?.push(action.payload.data);
      })
      .addCase(create_lead_source_provider.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_lead_source_provider.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(update_lead_source_provider.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record?.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data,
        };
      })
      .addCase(update_lead_source_provider.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_lead_source_provider.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(delete_lead_source_provider.fulfilled, (state, action) => {
        state.record = state.record?.filter(
          ({ _id }) => _id !== action?.payload?.data._id
        );
        state.isLoading = false;
      })
      .addCase(delete_lead_source_provider.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
export const { leadSourceProviderReset } = leadSourceProviderSlice.actions;
export default leadSourceProviderSlice.reducer;
