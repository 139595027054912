import React, { useState } from "react";
import { Input, Button, Loader } from "../../components";

import { new_logo } from "../../images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldHalved } from "@fortawesome/free-solid-svg-icons";

import { useDispatch, useSelector } from "react-redux";

import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  check_reset_token,
  reset_password_confirm,
} from "../../features/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ConfirmPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, isError, message } = useSelector((state) => state.auth);
  const { token } = useParams();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [isMatch, setIsMatch] = useState(true);
  const submitHandler = async (event) => {
    event.preventDefault();
    if (password === confirmPassword) {
      try {
        const res = await dispatch(
          reset_password_confirm({
            data: {
              password: password,
              confirmPassword: confirmPassword,
            },
            token: token,
          })
        );
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.data?.message);
          navigate("/login");
        } else {
        }
      } catch (error) {
        console.error("Error", error);
      }
    } else {
      setIsMatch(false);
    }
  };
  const showPas = () => {
    setShowPassword(!showPassword);
  };

  React.useEffect(() => {
    dispatch(check_reset_token(token));
    // eslint-disable-next-line
  }, [token]);
  React.useEffect(() => {
    if (!isMatch) {
      if (password === confirmPassword) {
        setIsMatch(true);
      } else {
        setIsMatch(false);
      }
    }

    // eslint-disable-next-line
  }, [password, confirmPassword]);

  return (
    <div className="h-screen w-full bg-login_bg bg-no-repeat bg-cover font-mont flex justify-center items-center">
      <div className="w-[600px] max-w-[95%] rounded-md bg-white shadow-[0_1px_4px_rgba(0,_0,_0,_0.2)] p-4 text-center text-white">
        <div className="flex w-full justify-center items-center !mb-2">
          <img src={new_logo} alt="login logo" />
        </div>
        {!isLoading ? (
          !isError ? (
            <form onSubmit={submitHandler}>
              <div className="relative">
                <Input
                  id="password"
                  placeholder="Password"
                  className="bg-white text-[#38015c] border border-[#d7d7d7] w-[98%] text-left py-3 px-2.5 h-[60px]"
                  value={password}
                  onChange={({ target: { value } }) => setPassword(value)}
                  type={showPassword ? "text" : "password"}
                />
                <div className="absolute top-5 right-3.7 text-lg text-[#7c7f82]">
                  <FontAwesomeIcon icon={faShieldHalved} />
                </div>
              </div>
              <div className="relative">
                <Input
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  className="bg-white text-[#38015c] border border-[#d7d7d7] w-[98%] text-left py-3 px-2.5 h-[60px]"
                  value={confirmPassword}
                  type={showPassword ? "text" : "password"}
                  onChange={({ target: { value } }) =>
                    setConfirmPassword(value)
                  }
                />
                <div className="absolute top-5 right-3.7 text-lg text-[#7c7f82]">
                  <FontAwesomeIcon icon={faShieldHalved} />
                </div>
              </div>
              <div className="text-black mb-6 flex justify-between mt-1.5 px-2.5">
                <div className="cursor-pointer">
                  <input
                    type="checkbox"
                    id="showpass"
                    name="showpass"
                    value={showPassword}
                    onChange={showPas}
                    className="cursor-pointer"
                  />
                  <label htmlFor="showpass" className="ml-2 cursor-pointer">
                    Show Password
                  </label>
                </div>

                <NavLink
                  to={`/login`}
                  className="text-primary-100 text-base underline"
                >
                  I remembered my password!
                </NavLink>
              </div>
              <div>
                {!isLoading && (
                  <Button
                    text="Reset Password"
                    type="submit"
                    className="!w-1/2 bg-primary-100 text-white cursor-pointer !p-4 text-xl font-normal uppercase w-[98%]"
                  />
                )}
                {isLoading && <Loader />}
              </div>
            </form>
          ) : (
            isError && (
              <div className="text-[#f00] text-sm mb-3.7 mt-1.5">{message}</div>
            )
          )
        ) : (
          <Loader />
        )}
        {!isMatch && (
          <div className="text-[#f00] text-sm mb-3.7 mt-1.5">
            {"Password must match"}
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmPassword;
