import api from "./api";
export const callByLink = async (value) => {
  return await api.get(`/api/cc/agent_fun/external_dial?phone_num=${value}`);
};
export const getRecordings = async (job_number) => {
  return await api.post(`/api/cc/admin_fun/getLeadJobRecording`, job_number);
};
export const getRealTime = async () => {
  return await api.get(`/api/cc/admin_fun/realTime`);
};
export const getLeadDid = async ({ phone }) => {
  return await api.get(`/api/cc/dids/get_lead_did/${phone}`);
};
export const callMonitor = async (data) => {
  return await api.post(`/api/cc/admin_fun/call_monitor`, data);
};
export const getMissedCalls = async (data) => {
  return await api.get(`/api/cc/reports/missed_calls_report`, { params: data });
};
export const dialer_operations = async (data) => {
  return await api.post(
    `/api/vicidial/dialer_operations?operation_type=${data.type}`,
    { value: data?.value }
  );
};
