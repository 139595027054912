import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { get_users, get_password } from "../../features/usersSlice";
import { get_countries, get_states } from "../../features/countriesSlice";
import { get_roles } from "../../features/rolesSlice";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import dayjs from "dayjs";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { useLocation, useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Button, DotsLoader } from "../../components";
import api from "../../services/api";
import { get_inbound_group_drd } from "../../features/inboundGroupSlice";
import ChangePasswordModal from "./ChangePasswordModal";
import AddUpdateFormNew from "./AddUpdateFormNew";
import { filterModelRules, formatPhoneNumbers } from "../../util/common";
const User = () => {
  const initialBulkPayload = {
    barge: false,
    monitor: false,
    whisper: false,
    closer_campaigns: null,
  };
  const inbounds = useSelector((state) => state.inbound);
  const location = useLocation();
  const optionsRef = React.useRef();
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const { isLoading, users } = useSelector((state) => state.users);
  const roles = useSelector((state) => state.roles.record);
  const navigate = useNavigate();
  const [editingRecord, setEditingRecord] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [showMenu, setShowMenu] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [payload, setPayload] = useState(initialBulkPayload);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "AND",
    rules: [],
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const dispatch = useDispatch();
  const dialerRoles = [
    { label: "Agent", value: 1 },
    { label: "Admin", value: 8 },
  ];
  const dialerOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setIsPassword(false);
  };
  const handleHideDropdown = (event) => {
    if (showMenu && event.key === "Escape") {
      setShowMenu(null);
    }
  };
  const handleClickOutside = (event) => {
    if (
      showMenu &&
      optionsRef.current &&
      !optionsRef.current.contains(event.target)
    ) {
      setShowMenu(null);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const reloadData = () => {
    dispatch(
      get_users({
        filters: queryOptions,
        ...sortingModel,
        page: paginationModel.page,
        size: paginationModel.pageSize,
      })
    );
  };
  const activateDeactivateHandler = async (record_id, status) => {
    const c = window.confirm(`Are you sure want to ${status} user`);
    if (!c) return;
    setIsLoader(true);
    try {
      const res = await api.patch(
        `/api/users/toggle_active_status/${record_id}`
      );
      if (res.status === 200) {
        toast.success(res.data?.message || `${status}d user successfully`);
        reloadData();
      } else {
        toast.error(res.data?.message || `Cannot ${status}d user`);
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error(err?.response?.data?.message || `Cannot ${status}d user`);
    }
  };

  const openFormHandler = (record) => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const openPasswordHandler = (record) => {
    setEditingRecord(record);
    setIsPassword(true);
  };
  useEffect(() => {
    dispatch(
      get_users({
        filters: queryOptions,
        ...sortingModel,
        page: 1,
        size: paginationModel.pageSize,
      })
    );
    dispatch(get_roles());
    dispatch(get_countries());
    dispatch(get_states());
    dispatch(get_inbound_group_drd());
    // eslint-disable-next-line
  }, []);
  const sendVerificationEmail = async (mail) => {
    const c = window.confirm(
      "Are you sure want to send the verification email?"
    );
    if (!c) return;
    setIsLoader(true);
    try {
      const res = await api.post("/api/users/send-verification-email", {
        email: mail,
      });
      if (res.status === 200) {
        toast.success(
          res.data?.message || "Verification email send successfully"
        );
      } else {
        toast.error(res.data?.message || "Verification email couldn't be send");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error(
        err?.response?.data?.message || "Verification email couldn't be send"
      );
    }
  };
  const filterUsers = users?.users?.filter((user) => {
    const searchTextLower = searchText.toLowerCase().trim();
    const userFirstNameLower = user?.first_name?.toLowerCase() || "";
    const userLastNameLower = user?.last_name?.toLowerCase() || "";
    const userEmailLower = user?.email?.toLowerCase() || "";
    const userNameLower = user?.username?.toLowerCase() || "";
    const userRoleNameLower = user?.role_id?.name?.toLowerCase() || "";
    const userPrimaryMerchantNameLower =
      user?.primary_merchant_id?.name?.toLowerCase() || "";
    const userFullNameLower = `${userFirstNameLower} ${userLastNameLower}`;
    const lastLoginIp = user?.last_login_ip?.toLowerCase() || "";
    return (
      userFullNameLower.includes(searchTextLower) ||
      userEmailLower.includes(searchTextLower) ||
      userNameLower.includes(searchTextLower) ||
      userRoleNameLower.includes(searchTextLower) ||
      lastLoginIp.includes(searchTextLower) ||
      userPrimaryMerchantNameLower.includes(searchTextLower)
    );
  });
  const handleGetpassword = async (record, password) => {
    if (password) {
      return setShowPassword(record);
    }
    setIsLoader(true);
    try {
      const res = await api.get(`/api/users/password/${record}`);
      if (res?.status === 200) {
        setShowPassword(record);
        const newData = [...users?.users];
        const resultIndex = newData.findIndex(({ _id }) => _id === record);
        if (resultIndex > -1) {
          newData[resultIndex] = {
            ...newData[resultIndex],
            pswrd: res?.data.password,
          };
          dispatch(
            get_password({ users: newData, totalItems: users?.totalItems })
          );
        }
      }
    } catch (err) {
      console.log("👊 ~ searchVerifiedJob ~ err:", err);
    }
    setIsLoader(false);
  };
  const testMenu = [
    { label: "Edit User", action: (e) => openFormHandler(e) },
    {
      label: "Active",
      action: (e) => {
        activateDeactivateHandler(e._id, e.active ? "Deactivate" : "Activate");
      },
      isHide: user?.company_name === "Verified CRM",
    },
    { label: "Change Password", action: (e) => openPasswordHandler(e) },
  ];
  const onSingleSelect = ({ checked, data }) => {
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (did) => did?._id !== data?._id
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onSelectAll = (checked) => {
    if (checked) {
      setSelectedData([...filterUsers]);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (user) => {
    if (selectedData?.length > 0) {
      if (selectedData?.filter(({ _id }) => _id === user._id).length > 0) {
        return true;
      }
    }
    return false;
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: () => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={selectedData?.length === filterUsers?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <input
          type="checkbox"
          disabled={params.row.role === "Super Administrator"}
          checked={isSelected(params.row.records)}
          onChange={(e) => {
            onSingleSelect({
              checked: e.target.checked,
              data: params.row.records,
            });
          }}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
      disableColumnMenu: true,
    },
    { headerName: "#", field: "counter", width: 80, filterable: false },
    { headerName: "Name", field: "name", filterable: false },

    { headerName: "First Name", field: "first_name" },
    { headerName: "Last Name", field: "last_name" },

    { headerName: "Phone", field: "phone" },
    {
      headerName: "Email",
      field: "email",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div className="flex flex-col">
          <span>{params.row.email}</span>
          {params.row.email_verified ? (
            <small className="text-green-600">Verified</small>
          ) : (
            <small
              className="text-red-600 hover:underline cursor-pointer ml3"
              onClick={() => sendVerificationEmail(params.row.email)}
            >
              Not verified
            </small>
          )}
        </div>
      ),
    },
    { headerName: "User Name", field: "username" },
    {
      field: "pswrd",
      headerName: "Password",
      width: 130,
      renderCell: (params) => {
        return (
          <div className="">
            <span className="!mr-2">
              {showPassword === params.row.records?._id
                ? params.row.records.pswrd
                : "*******"}
            </span>
            {showPassword === params.row.records._id ? (
              <FaEye
                className="my_navIcon cursor-pointer"
                onClick={() => setShowPassword("")}
              />
            ) : (
              <FaEyeSlash
                className="my_navIcon cursor-pointer"
                onClick={() =>
                  handleGetpassword(
                    params.row.records._id,
                    params.row.records.pswrd
                  )
                }
              />
            )}
          </div>
        );
      },
    },
    {
      headerName: "Role",
      field: "role_id",
      width: 100,
      type: "singleSelect",
      getOptionValue: (option) => option?._id,
      getOptionLabel: (option) => option.name,
      valueOptions: roles,
      renderCell: (params) => {
        const list = roles.find((list) => list.name === params?.row?.role);
        return list ? list.name : "N/A";
      },
    },
    {
      headerName: "Permissions",
      field: "berge",
      filterable: false,
      width: 100,
      renderCell: (params) => {
        const {
          barge = false,
          monitor = false,
          whisper = false,
        } = params.row?.records.call_monitor_permissions || {};
        return (
          <div className="flex flex-col">
            <span>Barge: {barge ? "Yes" : "No"}</span>
            <span>Monitor: {monitor ? "Yes" : "No"}</span>
            <span>Whisper:{whisper ? "Yes" : "No"}</span>
          </div>
        );
      },
    },
    {
      headerName: "Is Dialer User",
      field: "is_dialer_user",
      width: 100,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: dialerOptions,
      renderCell: (params) => {
        const item = dialerOptions.find(
          (item) => item.label === params?.row?.is_dialer_user
        );
        return item ? item.label : "N/A";
      },
    },
    {
      headerName: "Dialer Info",
      field: "dialer_user",
      filterable: false,
      width: 100,
      renderCell: (params) => {
        return params?.row?.is_dialer_user === "Yes" ||
          params?.row?.dialer_user_info?.dialer_user ? (
          <div className="flex flex-col">
            <span>
              User: {params.row.dialer_user_info?.dialer_user || "N/A"}
            </span>
            <span>
              Phone:{" "}
              {formatPhoneNumbers(params.row.dialer_user_info?.dialer_phone) ||
                "N/A"}
            </span>
            <span>
              Role:{" "}
              {dialerRoles?.find(
                ({ value }) =>
                  value === params.row.dialer_user_info?.dialer_role
              )?.label || "N/A"}
            </span>
            <span>
              DID:{" "}
              {dialerRoles?.find(
                ({ value }) =>
                  value === params.row.dialer_user_info?.dialer_user_did
              )?.label || "N/A"}
            </span>
          </div>
        ) : (
          "N/A"
        );
      },
    },
    {
      headerName: "Created At",
      field: "createdAt",
      width: 120,
      sortable: true,
      valueGetter: (params) => params.row.createdAt,
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
    },
    {
      headerName: "Status",
      field: "active",
      filterOperators: [
        { label: "Active", value: "true" },
        { label: "Deactive", value: "false" },
      ],
      renderCell: (params) =>
        params.row.active ? <span>{`${params.row.active}`}</span> : "",
    },
    {
      headerName: "Action",
      field: "actions",
      align: "center",
      filterable: false,
      renderCell: (params) => (
        <div>
          <BsThreeDotsVertical
            size={18}
            className="cursor-pointer !relative hover:text-primary-100"
            onClick={() => setShowMenu(params.row.records._id)}
          />
          {showMenu === params.row.record_id ? (
            <div
              ref={optionsRef}
              className="absolute w-auto right-12 min-w-[100px] rounded shadow !mt-1 bg-white z-20 border-x border-y border-[#ddd] -ml-px overflow-hidden transition ease-in-out delay-150"
            >
              <ul className="!pl-0 !mb-0">
                {testMenu?.map((option, index) => {
                  return !option.isHide ? (
                    <li
                      key={index}
                      className="cursor-pointer px-3 !py-1.25 border- border-[#ddd] hover:bg-[#e1e1e1]"
                      onClick={() => option.action(params.row.records)}
                    >
                      {option.label === "Active"
                        ? params.row.records.active
                          ? "Deactivate User"
                          : "Activate User"
                        : option.label}
                    </li>
                  ) : null;
                })}
              </ul>
            </div>
          ) : null}
        </div>
      ),

      width: 100,
    },
    {
      headerName: "Dialer User",
      field: "dialer_user_info.dialer_user",
      type: "number",
    },
    {
      headerName: "Dialer Phone",
      field: "dialer_user_info.dialer_phone",
      type: "number",
      hide: true,
    },
    {
      headerName: "Dialer Role",
      field: "dialer_user_info.dialer_role",
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: dialerRoles,
      hide: true,
    },
    {
      headerName: "Dialer DID",
      field: "dialer_user_info.dialer_user_did",
      width: 100,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: dialerRoles,
      hide: true,
    },
  ].filter(Boolean);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    dispatch(
      get_users({
        page: 1,
        ...sortingModel,
        size: paginationModel.pageSize,
        filters: queryOptions,
      })
    );
  };

  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      dispatch(
        get_users({
          ...sortingModel,
          filters: queryOptions,
          page: 1,
          size: paginationModel.pageSize,
        })
      );
    }
    // eslint-disable-next-line array-callback-return
    ruless = filterModelRules(filterModel, ruless);
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_users({
        ...sortingModel,
        filters: queryOptions,
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  const CustomButtons = () => {
    return (
      <div className="flex gap-x-2">
        <Button
          text="Add New User"
          variant="btn_submit"
          onClick={() => openFormHandler(0)}
        />
        <Button
          text="Manage Groups"
          variant="btn_submit"
          onClick={() =>
            navigate(`${location?.pathname?.replace("users", "user_groups")}`)
          }
        />
        <Button
          text="Roles & Permissions"
          variant="btn_submit"
          onClick={() =>
            navigate(
              `${location?.pathname?.replace("users", "user_permissions")}`
            )
          }
        />
      </div>
    );
  };

  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;

  const bulkOptions = [
    {
      placeholder: "Select Inbounds",
      name: "closer_campaigns",
      label: "Select Inbounds",
      options: inbounds?.inboundDrd,
      valueProp: "group_id",
      labelProp: "group_name",
      isMulti: true,
      onChange: (e) => setPayload({ ...payload, closer_campaigns: e.value }),
      value: payload?.closer_campaigns,
    },
    {
      id: "barge",
      placeholder: "Barge",
      name: "barge",
      isCheckbox: true,
      onChange: () => setPayload({ ...payload, barge: !payload?.barge }),
      value: payload?.barge,
    },
    {
      id: "monitor",
      placeholder: "Monitor",
      name: "monitor",
      isCheckbox: true,
      onChange: () => setPayload({ ...payload, monitor: !payload?.monitor }),
      value: payload?.monitor,
    },
    {
      id: "whisper",
      placeholder: "Whisper",
      name: "whisper",
      isCheckbox: true,
      onChange: () => setPayload({ ...payload, whisper: !payload?.whisper }),
      value: payload?.whisper,
    },
  ];
  const update_bulk_user = async () => {
    const values = selectedData?.map(({ _id }) => ({
      user_id: _id,
      ...payload,
    }));
    try {
      const res = await api.post("/api/users/bulk_update", values);
      if (res.status === 200) {
        toast.success("Users updated successfully");
        setSelectedData([]);
        setPayload(initialBulkPayload);
      } else {
        toast.error(res.data?.message || "Users couldn't be updated");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || "Users couldn't be updated");
      console.log("👊 ~ constupdate_bulk_user=async ~ err:", err);
    }
  };
  const getRowHeight = (params) => {
    const data = params?.model;
    if (data?.is_dialer_user === "Yes" || data?.dialer_user?.dialer_user) {
      return 90;
    } else {
      return 80;
    }
  };
  return (
    <>
      {isEditing && (
        <AddUpdateFormNew
          editingRecord={editingRecord}
          modalTitle={editingRecord ? "Update User" : "Add User"}
          onCancelForm={cancelFormHandler}
          reloadData={reloadData}
        />
      )}
      {isPassword ? (
        <ChangePasswordModal
          onClose={cancelFormHandler}
          editingRecord={editingRecord}
          reloadData={reloadData}
        />
      ) : null}
      {isLoader ? <DotsLoader /> : null}
      <PageHeader
        route="Setting > Users"
        heading=""
        CustomButtons={CustomButtons}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={filterUsers?.map((record, index) => {
            const {
              email_verified,
              receiving_leads,
              is_dialer_user,
              phone,
              first_name,
              last_name,
            } = record;
            let records = record;
            let counter = offset + index + 1;
            let record_id = record._id;
            let name = first_name + " " + (last_name ? last_name : "");
            let email = record.email;
            let username = record?.username;
            let role = record?.role_id?.name;
            let role_level = record?.role_id?.level;
            let primaryMerchant = record?.primary_merchant_id?.name;
            let createdAt = record.createdAt
              ? dayjs(record?.createdAt).format("ddd, MMM D, YYYY h:mm A")
              : null;
            let lastLoginIp = record.last_login_ip;
            let lastLoginDate = record.last_login_date
              ? dayjs(record.last_login_date).format("ddd, MMM D, YYYY h:mm A")
              : "";
            let ipFiltering = record.ip_filtering ? "Yes" : "No";
            let active = record.active ? "Active" : "Deactive";
            let pswrd = record.pswrd;
            let dialer_user_info = record?.dialer_user_info || {};
            return {
              counter,
              name,
              email,
              username,
              role,
              primaryMerchant,
              createdAt,
              lastLoginIp,
              lastLoginDate,
              ipFiltering,
              active,
              records,
              record_id,
              pswrd,
              role_level,
              email_verified,
              receiving_leads,
              dialer_user_info,
              phone,
              is_dialer_user: is_dialer_user ? "Yes" : "No",
              first_name,
              last_name,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          totalItems={users.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          density="standard"
          height="66vh"
          toolbarProps={{
            isBulkUpdate: true,
            setSelectedData: setSelectedData,
            selectedItem: selectedData?.length,
            fieldsArray: bulkOptions,
            isSelectAll: selectedData?.length === users?.users?.length,
            handleBulkSave: update_bulk_user,
            applyFilter: handleNewFilter,
            isHideColumnButton: true,
            isHideDensityButton: true,
            onCancel: () => {
              setSelectedData([]);
              setPayload(initialBulkPayload);
            },
          }}
          gridOptions={getRowHeight}
          columnVisibilityModel={{
            "dialer_user_info.dialer_user": false,
            "dialer_user_info.dialer_phone": false,
            "dialer_user_info.dialer_role": false,
            "dialer_user_info.dialer_user_did": false,
            first_name: false,
            last_name: false,
          }}
        />
      </div>
    </>
  );
};

export default User;
