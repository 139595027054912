import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./app/store";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { LicenseInfo } from "@mui/x-license-pro";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import * as Sentry from "@sentry/react";
import "./index.css";
import "react-tabs/style/react-tabs.css";
import "react-phone-number-input/style.css";
LicenseInfo.setLicenseKey(
  "b572720c5111a0d0cf3d6c75a9f06220Tz02MTk2NixFPTE3MTAzOTYwOTIyNzIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
reportWebVitals();
