// src/hooks/useSocket.js
import { useEffect, useRef, useCallback, useState } from "react";
import { io } from "socket.io-client";

export const useSocket = (token) => {
  const [isConnected, setIsConnected] = useState(false);
  const socketRef = useRef(null);
  const reconnectAttemptsRef = useRef(0);
  const maxReconnectAttempts = 5;
  const listenersRef = useRef(new Map());
  const pingIntervalRef = useRef(null);
  const connect = useCallback(() => {
    if (socketRef.current?.connected || !token) return;

    const serverUrl =
      process.env.REACT_APP_BACKEND_URL || "https://sapi.verifiedcrm.com";
    socketRef.current = io(serverUrl, {
      auth: {
        token: `Bearer ${token}`, // Try adding Bearer prefix
      },
      // transports: ["websocket", "polling"], // Explicitly specify transports
      transports: ["polling", "websocket"], // Try polling first
      reconnectionAttempts: maxReconnectAttempts,
      timeout: 20000,
      // reconnection: true,
      // reconnectionDelay: 1000,
      // reconnectionDelayMax: 5000,
      // reconnectionAttempts: maxReconnectAttempts,
      // timeout: 20000,
    });

    // Setup connection handlers
    socketRef.current.on("connect", () => {
      setIsConnected(true);
      reconnectAttemptsRef.current = 0;
    });

    socketRef.current.on("connect_error", (error) => {
      console.error("Connection error:", error);
      setIsConnected(false);
      reconnectAttemptsRef.current++;

      if (reconnectAttemptsRef.current >= maxReconnectAttempts) {
        console.error("Max reconnection attempts reached");
        disconnect();
      }
    });

    socketRef.current.on("disconnect", () => {
      console.log("Disconnected from socket server");
      setIsConnected(false);
    });
    // Setup event handlers
    [
      "notification",
      "lead_action_response",
      "pong",
      "test_response",
      "verified_crm_message_list",
    ].forEach((event) => {
      socketRef.current.on(event, (data) => {
        const listeners = listenersRef.current.get(event);
        listeners?.forEach((callback) => callback(data));
      });
    });

    // Setup ping interval
    pingIntervalRef.current = setInterval(() => {
      if (socketRef.current?.connected) {
        socketRef.current.emit("ping");
      }
    }, 30000);
    // eslint-disable-next-line
  }, []);

  const disconnect = useCallback(() => {
    if (pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current);
    }

    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = null;
    }
    setIsConnected(false);
  }, []);

  const emit = useCallback((event, data) => {
    if (!socketRef.current?.connected) {
      console.error("Socket not connected");
      return false;
    }

    try {
      socketRef.current.emit(event, data);
      return true;
    } catch (error) {
      console.error("Error emitting event:", error);
      return false;
    }
  }, []);

  const subscribe = useCallback((event, callback) => {
    if (!listenersRef.current.has(event)) {
      listenersRef.current.set(event, new Set());
    }
    listenersRef.current.get(event).add(callback);

    return () => {
      const listeners = listenersRef.current.get(event);
      if (listeners) {
        listeners.delete(callback);
        if (listeners.size === 0) {
          listenersRef.current.delete(event);
        }
      }
    };
  }, []);

  // Connect on mount and disconnect on unmount
  useEffect(() => {
    connect();
    return () => disconnect();
  }, [connect, disconnect]);

  return {
    isConnected,
    emit,
    subscribe,
    disconnect,
    connect,
  };
};
