import api from "./api";

export const getConversation = async (data) => {
  return await api.post(`/api/message_center/get_conversation_new`, data);
};
export const sendMessage = async (data) => {
  return await api.post(`/api/message_center/send_message`, data);
};
export const getRecentMsgList = async (data) => {
  return await api.get("/api/message_center/get_mc_nav", { params: data });
};
export const markAsRead = async (data) => {
  return await api.post("/api/message_center/mark_messages_as_read_new", data);
};
export const markAsGroupRead = async (data) => {
  return await api.post("api/message_center/mark_group_messages_as_read", data);
};
// Group Message APIs
export const getGroupRecentMsgList = async (data) => {
  return await api.get("/api/message_center/get_recent_group_conversations", {
    params: data,
  });
};
export const getGroupConversation = async (data) => {
  return await api.post(`/api/message_center/get_group_conversation`, data);
};
export const sendGroupMessage = async (data) => {
  return await api.post(`/api/message_center/send_conversation_message`, data);
};
export const getMessageUsers = async (data) => {
  return await api.post(`/api/message_center/get_mc_users`, data);
};

export const receiveMsg = async () => {
  return await api.post("/api/webhooks/receive_message", {
    deliveryReceipt: false,
    from: "913031234567",
    referenceId: "a42ea4f3-e129-479d-8ab0-1b6bf8927e8e",
    text: "Test msg testing custom msg",
    to: ["12027880683"],
  });
};
export const receiveGroupMsg = async () => {
  return await api.post("/api/webhooks/receive_group_message", {
    ConversationSid: "CHbac6e7c7e19d4db1995cd4583e83e092",
    Body: "Hello Testing",
    Author: "+17026603502",
  });
};
