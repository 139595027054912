import api from "./api";

// Distribution Programs
export const getDistributionPrograms = async () => {
  try {
    const response = await api.get("/api/distribution_program/programs/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createDistributionProgram = async (data) => {
  try {
    const response = await api.post(
      "/api/distribution_program/programs/add",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateDistributionProgram = async ({ payload, id }) => {
  try {
    const response = await api.put(
      `/api/distribution_program/programs/update/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteDistributionProgram = async (id) => {
  try {
    const response = await api.delete(
      `/api/distribution_program/programs/delete/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Program Schedules
export const getProgramSchedules = async (distributionProgramId) => {
  try {
    const response = await api.get(
      `/api/distribution_program/schedules/list/${distributionProgramId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createProgramSchedule = async (data) => {
  try {
    const response = await api.post(
      "/api/distribution_program/schedules/add",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateProgramSchedule = async ({ payload, id }) => {
  try {
    const response = await api.put(
      `/api/distribution_program/schedules/update/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteProgramSchedule = async (id) => {
  try {
    const response = await api.delete(
      `/api/distribution_program/schedules/delete/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Lead Filters
export const getLeadFilters = async (leadDistributionId) => {
  try {
    const response = await api.get(
      `/api/distribution_program/lead_filters/list/${leadDistributionId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createLeadFilter = async (data) => {
  try {
    const response = await api.post(
      "/api/distribution_program/lead_filters/add",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateLeadFilter = async ({ payload, id }) => {
  try {
    const response = await api.put(
      `/api/distribution_program/lead_filters/update/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteLeadFilter = async (id) => {
  try {
    const response = await api.delete(
      `/api/distribution_program/lead_filters/delete/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// User Program Lead Counts
export const getProgramUsers = async (programId) => {
  try {
    const response = await api.get(
      `/api/distribution_program/user_filters/programs/assigned_users/${programId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserProgramLeads = async (userId) => {
  try {
    const response = await api.get(
      `/api/distribution_program/user_filters/users/assigned_programs/${userId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createUserProgramLeadCount = async (data) => {
  try {
    const response = await api.post(
      "/api/distribution_program/user_filters/lead_limits/add",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createUserProgramLeadCountBulk = async (data) => {
  try {
    const response = await api.post(
      "/api/distribution_program/user_filters/lead_limits/bulk",
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUserProgramLeadCount = async (data) => {
  try {
    const { _id, ...payload } = data;
    const allowedFields = ["lead_count", "lead_interval", "lead_interval_unit"];

    const filteredPayload = Object.entries(payload)
      .filter(([key, value]) => allowedFields.includes(key) && value !== "")
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

    const response = await api.put(
      `/api/distribution_program/user_filters/lead_limits/update/${_id}`,
      filteredPayload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
