import React, { useEffect } from "react";
import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { create_user, update_user } from "../../features/usersSlice";
import FormRadioGroup from "../../components/molecules/FormRadioGroup";
import { toast } from "react-toastify";
import { MultiSelect } from "primereact/multiselect";
import { prepareUserData, validateUserData } from "../../schemas/usersSchema";
import api from "../../services/api.js";
import userPermission from "../../util/userPermission.js";
const AddUpdateFormNew = ({
  editingRecord,
  onCancelForm,
  modalTitle,
  reloadData,
}) => {
  const users = useSelector((state) => state.users);

  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.roles);
  const inbounds = useSelector((state) => state.inbound);
  const [errors, setErrors] = React.useState([]);
  const [dialerRecords, setdialerRecords] = React.useState([]);
  let initialValues = {
    first_name: "",
    last_name: "",
    phone: "",
    fax: "",
    email: "",
    role_id: "",
    username: "",
    is_dialer_user: false,
    call_center_role: "",
    closer_campaigns: [],
    dialer_user_did: "",
    call_monitor_permissions: {
      barge: false,
      monitor: false,
      whisper: false,
    },
  };

  if (editingRecord) {
    const {
      _id,
      first_name,
      last_name,
      phone,
      fax,
      email,
      role_id,
      username,
      is_dialer_user,
      dialer_user_info,
      call_monitor_permissions,
    } = editingRecord;
    const {
      closer_campaigns = [],
      dialer_role = "",
      dialer_phone = "",
      dialer_user = "",
      dialer_user_did = "",
    } = dialer_user_info || {};
    initialValues = {
      id: _id,
      first_name,
      last_name,
      phone,
      fax,
      email,
      role_id: role_id?._id,
      username,
      is_dialer_user,
      call_center_role: dialer_role,
      dialer_user_did,
      call_monitor_permissions,
      closer_campaigns,
      dialer_phone,
      dialer_user,
    };
  }

  useEffect(() => {
    getRecords();
  }, []);

  const getRecords = async () => {
    try {
      const res = await api.get(
        `api/call_centers/outbound-did/get_records_by_did_type`
      );
      if (res.status === 200 && res.data.length) {
        const filteredData = res.data.filter(
          (item) =>
            item?.did_pattern ===
              editingRecord?.dialer_user_info?.dialer_user_did ||
            !item?.assigned
        );
        const formattedData = filteredData.map((item) => ({
          label: item.did_pattern,
          value: item.did_pattern,
        }));
        setdialerRecords(formattedData);
      }
    } catch (err) {
      console.log("👊 ~ outbound-did=async ~ err:", err);
    }
  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setErrors([]);
      const { isValid, errors: validationErrors } = validateUserData(
        values,
        editingRecord ? "update" : "create"
      );
      if (!isValid) {
        return setErrors(Object.values(validationErrors));
      }
      const preparedValues = prepareUserData(
        values,
        editingRecord ? "update" : "create"
      );
      if (!editingRecord) {
        try {
          const res = await dispatch(create_user(preparedValues));
          if (res?.payload?.status === 200) {
            toast.success("User created");
            reloadData();
            onCancelForm();
          } else {
            if (Array.isArray(res.payload)) {
              setErrors(res.payload);
            } else {
              toast.error(
                res.payload ? res.payload : "User couldn't be created"
              );
            }
          }
        } catch (error) {
          console.error(
            "ERROR ADD USER ~ file: AddUpdateFormNew.jsx ~ line 79 ~ onSubmit: ~ error",
            error
          );

          toast.error("Error, User couldn't be created");
        }
      } else {
        try {
          const res = await dispatch(
            update_user({ id: editingRecord?._id, data: preparedValues })
          );
          if (res?.payload?.status === 200) {
            toast.success("User updated");
            reloadData();
            onCancelForm();
          } else {
            if (Array.isArray(res.payload)) {
              setErrors(res.payload);
            } else {
              toast.error(
                res.payload ? res.payload : "User couldn't be updated"
              );
            }
          }
        } catch (error) {
          console.error(
            "ERROR ADD USER ~ file: AddUpdateFormNew.jsx ~ line 79 ~ onSubmit: ~ error",
            error
          );
          error?.forEach((error) => {
            const field = Object.keys(error)[0]; // get the field name from the error object
            formik.setErrors(field, error[field]); // set the error for the field
          });
        }
      }
    },
  });
  const isReadOnly = false;
  return (
    <>
      {isLoading || users.isLoading ? <DotsLoader /> : null}
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={() => {}}
        modalClass="!min-w-[70%] !max-w-[100%]"
        mainClass="!min-w-[70%] max-w-[100%]"
        modalBodyClass="!min-w-[80%] max-w-[100%]"
      >
        <form className="">
          <div className="">
            <h5 className="text-client-50 pb-2.5 w-full border-b border-client-50">
              Basic Information
            </h5>
            <div className="grid md:grid-cols-3 gap-x-5">
              <div className="mt-3">
                <FormInput
                  errors={errors}
                  name="first_name"
                  label="First Name"
                  formik={formik}
                  disabled={isReadOnly}
                />
              </div>
              <div className="mt-3">
                <FormInput
                  errors={errors}
                  name="last_name"
                  label="Last Name"
                  formik={formik}
                  disabled={isReadOnly}
                />
              </div>
              <div className="mt-3">
                <FormInput
                  errors={errors}
                  name="phone"
                  label="Phone"
                  formik={formik}
                />
              </div>
              <div className="mt-2">
                <FormInput
                  errors={errors}
                  name="fax"
                  label="Fax"
                  formik={formik}
                  disabled={isReadOnly}
                />
              </div>

              <div className="mt-2">
                <FormInput
                  errors={errors}
                  name="email"
                  label="Email"
                  formik={formik}
                />
              </div>
              <div className="mt-2">
                <FormSelectInput
                  errors={errors}
                  name="role_id"
                  label="Role"
                  formik={formik}
                  options={record}
                  valueProp="_id"
                  labelProp="name"
                />
              </div>
              <div className="mt-2">
                <FormInput
                  errors={errors}
                  name="username"
                  label="Username"
                  formik={formik}
                  disabled={!!editingRecord}
                />
              </div>
              {record?.find(({ _id }) => _id === formik.values.role_id)
                ?.name === "Tech" ? null : (
                <div className="mt-2 relative">
                  <FormRadioGroup
                    errors={errors}
                    name="is_dialer_user"
                    label="Dialer User"
                    labelProp="label"
                    valueProp="value"
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    // eslint-disable-next-line
                    convertor={(value) => value === "true"}
                    formik={formik}
                    type="text"
                    isHorizontal
                    isBorder
                    isFloat
                    disabled={isReadOnly}
                    className="bg-white"
                  />
                </div>
              )}

              {
                <div className="flex flex-row justify-between mt-2 items-center">
                  <div>
                    <input
                      type="checkbox"
                      id="barge"
                      name="barge"
                      checked={formik.values?.call_monitor_permissions?.barge}
                      onChange={() =>
                        formik.setFieldValue(
                          "call_monitor_permissions.barge",
                          !formik.values?.call_monitor_permissions?.barge
                        )
                      }
                      className="cursor-pointer"
                    />
                    <label htmlFor="barge" className="ml-2 cursor-pointer">
                      Barge
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="monitor"
                      name="monitor"
                      checked={formik.values?.call_monitor_permissions?.monitor}
                      onChange={() =>
                        formik.setFieldValue(
                          "call_monitor_permissions.monitor",
                          !formik.values?.call_monitor_permissions?.monitor
                        )
                      }
                      className="cursor-pointer"
                    />
                    <label htmlFor="monitor" className="ml-2 cursor-pointer">
                      Monitor
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="whisper"
                      name="whisper"
                      checked={formik.values?.call_monitor_permissions?.whisper}
                      onChange={() =>
                        formik.setFieldValue(
                          "call_monitor_permissions.whisper",
                          !formik.values?.call_monitor_permissions?.whisper
                        )
                      }
                      className="cursor-pointer"
                    />
                    <label htmlFor="whisper" className="ml-2 cursor-pointer">
                      Whisper
                    </label>
                  </div>
                </div>
              }
            </div>
            <div>
              {formik.values.is_dialer_user ? (
                <div className="!py-5 ">
                  <h5 className="!mb-3 text-client-50 pb-2.5 w-full border-b border-client-50">
                    Call Center Settings
                  </h5>
                  <div className="grid md:grid-cols-2 gap-x-5 !mt-5">
                    <div className="mt-2">
                      <MultiSelect
                        placeholder="Select Inbounds"
                        options={inbounds?.inboundDrd}
                        optionLabel="group_name"
                        optionValue="group_id"
                        filter
                        maxSelectedLabels={2}
                        value={formik.values.closer_campaigns}
                        onChange={(e) =>
                          formik.setFieldValue("closer_campaigns", e.value)
                        }
                        className="h-[38px] mb-2 w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                      />
                    </div>
                    <div className="mt-2">
                      <FormSelectInput
                        errors={errors}
                        name="call_center_role"
                        label="Call Center Role"
                        formik={formik}
                        options={[
                          { label: "Agent", value: 1 },
                          { label: "Admin", value: 8 },
                        ]}
                        valueProp="value"
                        labelProp="label"
                      />
                    </div>
                    {editingRecord ? (
                      <div className="mt-2">
                        <FormInput
                          errors={errors}
                          name="dialer_user"
                          label="Dialer User"
                          formik={formik}
                          readOnly={true}
                        />
                      </div>
                    ) : null}
                    {editingRecord ? (
                      <div className="mt-2">
                        <FormInput
                          errors={errors}
                          name="dialer_phone"
                          label="Dialer Phone"
                          formik={formik}
                          readOnly={true}
                        />
                      </div>
                    ) : null}
                    <div className="mt-2">
                      <FormSelectInput
                        errors={errors}
                        name="dialer_user_did"
                        label="Dialer User DID"
                        formik={formik}
                        options={dialerRecords}
                        valueProp="value"
                        labelProp="label"
                        disabled={
                          editingRecord?.dialer_user_info?.dialer_user_did &&
                          !userPermission("Change Dialer User DID")
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddUpdateFormNew;
