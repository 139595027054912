import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getDistributionPrograms,
  updateDistributionProgram,
  deleteDistributionProgram,
  createDistributionProgram,
  getProgramUsers,
  createUserProgramLeadCount,
  getUserProgramLeads,
  createUserProgramLeadCountBulk,
  updateUserProgramLeadCount,
  getProgramSchedules,
  createProgramSchedule,
  updateProgramSchedule,
  deleteProgramSchedule,
  getLeadFilters,
  createLeadFilter,
  updateLeadFilter,
  deleteLeadFilter,
} from "../services/distributionProgramService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  schedules: [],
  leadFilters: [],
  userFilters: null,
  message: "",
  programFilters: null,
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Distribution Program
export const get_distribution_program = createAsyncThunk(
  "get_distribution_program",
  async (data, thunkAPI) => {
    try {
      return await getDistributionPrograms(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create New Distribution Program
export const create_distribution_program = createAsyncThunk(
  "create_distribution_program",
  async (data, thunkAPI) => {
    try {
      return await createDistributionProgram(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Distribution Program
export const update_distribution_program = createAsyncThunk(
  "update_distribution_program",
  async ({ payload, id }, thunkAPI) => {
    try {
      return await updateDistributionProgram({ payload, id });
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Distribution Program
export const delete_distribution_program = createAsyncThunk(
  "delete_distribution_program",
  async (id, thunkAPI) => {
    try {
      return await deleteDistributionProgram(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Distribution Program Lead Count
export const get_user_program_lead = createAsyncThunk(
  "get_user_program_lead",
  async (data, thunkAPI) => {
    try {
      return await getProgramUsers(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get User Program
export const get_program_user_lead = createAsyncThunk(
  "get_program_user_lead",
  async (data, thunkAPI) => {
    try {
      return await getUserProgramLeads(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Distribution filter
export const create_user_program_lead_count = createAsyncThunk(
  "create_user_program_lead_count",
  async (data, slug, thunkAPI) => {
    try {
      return await createUserProgramLeadCount(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Create distribution program lead count
export const create_user_program_lead_count_bulk = createAsyncThunk(
  "create_user_program_lead_count_bulk",
  async (data, slug, thunkAPI) => {
    try {
      return await createUserProgramLeadCountBulk(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update Distribution Program
export const update_user_program_lead_count = createAsyncThunk(
  "update_user_program_lead_count",
  async (data, thunkAPI) => {
    try {
      return await updateUserProgramLeadCount(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Program Schedules
export const get_program_schedules = createAsyncThunk(
  "get_program_schedules",
  async (distributionProgramId, thunkAPI) => {
    try {
      return await getProgramSchedules(distributionProgramId);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const create_program_schedule = createAsyncThunk(
  "create_program_schedule",
  async (data, thunkAPI) => {
    try {
      return await createProgramSchedule(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const update_program_schedule = createAsyncThunk(
  "update_program_schedule",
  async ({ payload, id }, thunkAPI) => {
    try {
      return await updateProgramSchedule({ payload, id });
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const delete_program_schedule = createAsyncThunk(
  "delete_program_schedule",
  async (id, thunkAPI) => {
    try {
      return await deleteProgramSchedule(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Lead Filters
export const get_lead_filters = createAsyncThunk(
  "get_lead_filters",
  async (leadDistributionId, thunkAPI) => {
    try {
      return await getLeadFilters(leadDistributionId);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const create_lead_filter = createAsyncThunk(
  "create_lead_filter",
  async (data, thunkAPI) => {
    try {
      return await createLeadFilter(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const update_lead_filter = createAsyncThunk(
  "update_lead_filter",
  async ({ payload, id }, thunkAPI) => {
    try {
      return await updateLeadFilter({ payload, id });
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const delete_lead_filter = createAsyncThunk(
  "delete_lead_filter",
  async (id, thunkAPI) => {
    try {
      return await deleteLeadFilter(id);
    } catch (error) {
      console.log("rkjrejkb");
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const distributionProgramSlice = createSlice({
  name: "distributionProgram",
  initialState,
  reducers: {
    distributionProgramReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_distribution_program.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_distribution_program.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_distribution_program.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
      })
      .addCase(create_distribution_program.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_distribution_program.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.push(action.payload.data);
      })
      .addCase(create_distribution_program.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_distribution_program.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_distribution_program.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data,
        };
      })
      .addCase(update_distribution_program.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_distribution_program.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_distribution_program.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_distribution_program.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_user_program_lead.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_user_program_lead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userFilters = {
          ...action.payload?.data,
          userProgramLeadCount: action.payload?.data?.programLeadCounts,
        };
      })
      .addCase(get_user_program_lead.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.userFilters = null;
      })
      .addCase(create_user_program_lead_count.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_user_program_lead_count.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.push(action.payload.data);
      })
      .addCase(create_user_program_lead_count.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_user_program_lead_count_bulk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        create_user_program_lead_count_bulk.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.record.push(action.payload.data);
        }
      )
      .addCase(
        create_user_program_lead_count_bulk.rejected,
        (state, action) => {
          state.isLoading = false;
        }
      )
      .addCase(update_user_program_lead_count.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_user_program_lead_count.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data,
        };
      })
      .addCase(update_user_program_lead_count.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_program_user_lead.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_program_user_lead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.programFilters = action.payload?.data;
      })
      .addCase(get_program_user_lead.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.programFilters = null;
      })
      .addCase(get_program_schedules.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_program_schedules.fulfilled, (state, action) => {
        state.isLoading = false;
        state.schedules = action.payload?.data;
      })
      .addCase(get_program_schedules.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.schedules = [];
      })
      .addCase(create_program_schedule.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_program_schedule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.schedules.push(action.payload.data);
      })
      .addCase(create_program_schedule.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(update_program_schedule.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_program_schedule.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.schedules.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        if (index !== -1) {
          state.schedules[index] = {
            ...state.schedules[index],
            ...action.payload.data,
          };
        }
      })
      .addCase(update_program_schedule.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_program_schedule.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_program_schedule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.schedules = state.schedules.filter(
          ({ _id }) => _id !== action.payload.data._id
        );
      })
      .addCase(delete_program_schedule.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_lead_filters.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_lead_filters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.leadFilters = action.payload?.data;
      })
      .addCase(get_lead_filters.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.leadFilters = [];
      })
      .addCase(create_lead_filter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_lead_filter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.leadFilters.push(action.payload.data);
      })
      .addCase(create_lead_filter.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(update_lead_filter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_lead_filter.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.leadFilters.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        if (index !== -1) {
          state.leadFilters[index] = {
            ...state.leadFilters[index],
            ...action.payload.data,
          };
        }
      })
      .addCase(update_lead_filter.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_lead_filter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_lead_filter.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_lead_filter.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { distributionProgramReset } = distributionProgramSlice.actions;
export default distributionProgramSlice.reducer;
