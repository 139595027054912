import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getInboundReport,
  getInboundForwardingReport,
  getLeadSourceDrd,
  getDIDDrd,
} from "../services/reportsService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  inboundReport: [],
  inboundForwardingReport: [],
  dids: [],
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
export const get_did_drd = createAsyncThunk(
  "get_did_drd",
  async (data, thunkAPI) => {
    try {
      return await getDIDDrd();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_inbound_report = createAsyncThunk(
  "get_inbound_report",
  async (data, thunkAPI) => {
    try {
      return await getInboundReport(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_inbound_forwarding_report = createAsyncThunk(
  "get_inbound_forwarding_report",
  async (data, thunkAPI) => {
    try {
      return await getInboundForwardingReport(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_leadsource_drd = createAsyncThunk(
  "get_leadsource_drd",
  async (data, thunkAPI) => {
    try {
      return await getLeadSourceDrd(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const reportSlice = createSlice({
  name: "getReports",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
    resetAOOReport: (state) => {
      state.aooDataReport = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_inbound_report.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_inbound_report.fulfilled, (state, action) => {
        state.isLoading = false;
        state.inboundReport = action.payload?.data;
      })
      .addCase(get_inbound_report.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.inboundReport = [];
      })
      .addCase(get_inbound_forwarding_report.pending, (state) => {
        state.isLoading = true;
        state.inboundForwardingReport = [];
      })
      .addCase(get_inbound_forwarding_report.fulfilled, (state, action) => {
        state.isLoading = false;
        state.inboundForwardingReport = action.payload?.data;
      })
      .addCase(get_inbound_forwarding_report.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.inboundForwardingReport = [];
      })
      .addCase(get_leadsource_drd.pending, (state) => {
        state.isLoading = true;
        state.leadSourceDrd = [];
      })
      .addCase(get_leadsource_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        const sortedLeadSources = Array.isArray(action.payload?.data)
          ? [...action.payload?.data].sort((a, b) =>
              a?.name?.localeCompare(b?.name)
            )
          : [];
        state.leadSourceDrd = sortedLeadSources;
      })
      .addCase(get_leadsource_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(get_did_drd.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_did_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dids = action.payload?.data;
      })
      .addCase(get_did_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.dids = [];
      });
  },
});

export const { reset, resetAOOReport } = reportSlice.actions;
export default reportSlice.reducer;
