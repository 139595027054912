import React, { createContext, useContext, useCallback, useState } from "react";
import { createPortal } from "react-dom";
import { Bell, X, AlertCircle, CheckCircle, Clock } from "lucide-react";
import { useSocketContext } from "../context/socketContext";

// Notification Context
const NotificationContext = createContext(null);

// Custom hook for using notifications
export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error("useNotification must be used within NotificationProvider");
  }
  return context;
};

// Individual Notification Component
const NotificationItem = ({ notification, onClose }) => {
  const getIcon = () => {
    switch (notification.type) {
      case "action_period_start":
        return <Clock className="text-blue-500" />;
      case "action_period_end":
        return <AlertCircle className="text-yellow-500" />;
      case "active":
        return <CheckCircle className="text-green-500" />;
      default:
        return <Bell className="text-blue-500" />;
    }
  };

  return (
    <a
      href={
        notification.leadId ? `/leads/lead_AddEdit/${notification.leadId}` : "#"
      }
      className={`
        flex items-center gap-3 p-4 mb-2 rounded-lg shadow-lg 
        bg-white border-l-4 
        ${
          notification.type === "active"
            ? "border-l-green-500"
            : "border-l-yellow-500"
        }
        transform transition-all duration-300 hover:scale-102 cursor-pointer
        text-decoration-none
      `}
      onClick={(e) => {
        if (!notification.leadId) {
          e.preventDefault();
        }
        // Don't close automatically when it's a link
      }}
    >
      <div className="flex-shrink-0">{getIcon()}</div>
      <div className="flex-grow">
        <p className="text-sm font-medium text-gray-900">
          {notification.message}
        </p>
        {notification.subMessage && (
          <p className="text-xs text-gray-500 mt-1">
            {notification.subMessage}
          </p>
        )}
      </div>
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClose(notification.id);
        }}
        className="flex-shrink-0 p-1 rounded-full hover:bg-gray-100 transition-colors"
      >
        <X className="w-4 h-4 text-gray-500" />
      </button>
    </a>
  );
};

// Notification Container
const NotificationContainer = ({ notifications, onClose }) => {
  if (!notifications.length) return null;

  return createPortal(
    <div className="fixed top-4 right-4 z-50 w-96 max-w-[calc(100vw-2rem)] space-y-2">
      {notifications.map((notification) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          onClose={onClose}
        />
      ))}
    </div>,
    document.body
  );
};

// Notification Provider
export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback((notification) => {
    const id = Date.now();
    const newNotification = {
      id,
      ...notification,
    };

    setNotifications((prev) => [...prev, newNotification]);

    // Auto-remove notification after 5 seconds unless it's a warning
    if (
      notification.type !== "warning" &&
      notification.type !== "action_period_end"
    ) {
      setTimeout(() => {
        removeNotification(id);
      }, 5000);
    }
    // eslint-disable-next-line
  }, []);

  const removeNotification = useCallback((id) => {
    setNotifications((prev) =>
      prev.filter((notification) => notification.id !== id)
    );
  }, []);

  return (
    <NotificationContext.Provider
      value={{ addNotification, removeNotification }}
    >
      {children}
      <NotificationContainer
        notifications={notifications}
        onClose={removeNotification}
      />
    </NotificationContext.Provider>
  );
};

// Usage with Socket Context
export const NotificationHandler = () => {
  const socket = useSocketContext();
  const { addNotification } = useNotification();

  React.useEffect(() => {
    const handleNotification = (data) => {
      const minutesRemaining = Math.ceil(
        (new Date(data.leadData.expirationTime) - new Date()) / (1000 * 60)
      );
      // eslint-disable-next-line default-case
      switch (data.type) {
        case "lead_active":
          addNotification({
            type: "active",
            message: `Lead Number: ${data.leadData.leadNumber} is now active`,
            leadId: data.leadId,
          });
          break;

        case "action_period_start": // New: Handle action period start notification
          addNotification({
            type: "action_period_start",
            message: `Lead Number: ${data.leadData.leadNumber} action period starts in ${minutesRemaining} minutes`,
            leadId: data.leadId,
          });
          break;

        case "action_period_end": // New: Handle action period end notification
          addNotification({
            type: "action_period_end",
            message: `Lead Number: ${data.leadData.leadNumber} action period expires in ${minutesRemaining} minutes`,
            leadId: data.leadId,
          });
          break;

        case "lead_inactive_warning": // Existing case
          addNotification({
            type: "warning",
            message: `Lead Number: ${data.leadData.leadNumber} will be inactive in next 5 minutes`,
            leadId: data.leadId,
          });
          break;
      }
    };

    const unsubscribe = socket.subscribe("notification", handleNotification);
    return () => unsubscribe();
  }, [socket, addNotification]);

  return null;
};
