import api from "./api";

export const getLeadSourceProvider = async () => {
  return await api.get(`/api/lead_source_provider`);
};
export const getLeadSourceProviderDrd = async () => {
  return await api.get(`/api/lead_source_provider/drd`);
};
export const createLeadSourceProvider = async (data) => {
  return await api.post("/api/lead_source_provider", data);
};
export const updateLeadSourceProvider = async ({ _id, name }) => {
  return await api.put(`/api/lead_source_provider/${_id}`, { name });
};
export const deleteLeadSourceProvider = async (id) => {
  return await api.delete(`/api/lead_source_provider/${id}`);
};
