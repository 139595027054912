import React from "react";
import { menu } from "../../../images";
import { useSelector } from "react-redux";
import Button from "../../atoms/Button";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../atoms/Loader";
import { toast } from "react-toastify";
import DialerHeader from "./DialerHeader";
import { new_logo } from "../../../images";
import TopMenu from "../Sidebar/TopMenu";
import api from "../../../services/api";
import { RxCross2 } from "react-icons/rx";
const Header = (props) => {
  const navigate = useNavigate();
  const initialFilter = {
    searchBy: "",
    searchValue: "",
  };

  const [searchJobs, setSearchJobs] = useState(initialFilter);
  const [menuToggle, setMenuToggle] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const location = useLocation();
  const { status, ext } = useSelector((state) => state.dialer);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const style = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      minWidth: "180px",
      maxHeight: "31px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "31px",
      boxShadow: state.isFocused ? "none" : "none",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "31px",
    }),
  };
  const [isActive, setActive] = useState(false);

  const ToggleClass = () => {
    setActive(!isActive);
  };
  const formatPhoneNumber = (text) => {
    return text.replace(/^(\+)/, "").replace(/[\s()-]/g, "");
    // return text.replace(/^(\+1|1)/, "").replace(/[\s()-]/g, "");
  };
  const searchVerifiedJob = async () => {
    setIsLoader(true);
    try {
      const res = await api.post("/api/leadActivity/lead_deal_report", {
        filters: {
          groupOp: "OR",
          rules: [
            {
              field: "lead_number",
              op: "eq",
              data: searchJobs?.searchValue,
            },
            {
              field: "client_information.full_name",
              op: "eq",
              data: searchJobs?.searchValue,
            },
            {
              field: "client_information.first_name",
              op: "eq",
              data: searchJobs?.searchValue,
            },
            {
              field: "client_information.last_name",
              op: "eq",
              data: searchJobs?.searchValue,
            },
            {
              field: "client_information.home_phone",
              op: "eq",
              data: formatPhoneNumber(searchJobs?.searchValue),
            },
            {
              field: "client_information.work_phone",
              op: "eq",
              data: formatPhoneNumber(searchJobs?.searchValue),
            },
            {
              field: "client_information.mobile_phone",
              op: "eq",
              data: formatPhoneNumber(searchJobs?.searchValue),
            },
            {
              field: "client_information.email",
              op: "eq",
              data: searchJobs?.searchValue,
            },
            {
              field: "customer_number",
              op: "eq",
              data: searchJobs?.searchValue,
            },
          ],
        },
      });
      if (res?.status === 200) {
        if (res?.data?.data?.length) {
          navigate(`/leads/list?searchValue=${searchJobs.searchValue}`);
        }
        if (res?.data?.records?.length === 0) {
          toast.info("No record found");
        }
      }
    } catch (err) {
      console.log("👊 ~ searchVerifiedJob ~ err:", err);
    }
    setIsLoader(false);
  };
  const menuToggler = () => {
    setMenuToggle(!menuToggle);
    return props.toggleNavbar(!props.show_nav);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      searchVerifiedJob();
    }
  };

  return (
    <header
      className={`flex flex-col w-full bg-[#f7f7f7] top-0  border-b border-[#d6dadc] fixed z-[99] justify-between transition-all ease-in-out delay-150 
      ${
        !menuToggle
          ? "md:w-[calc(100%-235px)] ml-0 md:!ml-[235px]"
          : "ml-[235px] md:w-100% md:ml-0"
      }`}
    >
      <div className="flex flex-row w-full justify-between items-center px-2 md:px-0 flex-wrap md:flex-nowrap">
        <div className="flex flex-row items-center w-ful gap-x-2 px-3">
          <div className="sm:block md:block cursor-pointer">
            <img
              src={menu}
              alt="burgur"
              className="brightness-0"
              onClick={menuToggler}
            />
          </div>
          {!menuToggle ? (
            <div>
              <h3 className="hidden md:block font-medium text-[#5f5f5f] text-base mb2 w-auto mb-0">
                {`Welcome ${user?.first_name} ${user?.last_name} (${user?.username}) `}
              </h3>
            </div>
          ) : (
            ""
          )}

          {menuToggle ? (
            <TopMenu show_navlabel={true} menuToggle={menuToggle} />
          ) : null}
          {ext ? (
            <span className="font-medium text-[#5f5f5f] text-[14px]">
              Ext: {ext}
            </span>
          ) : null}
          {status ? (
            <div className="ml-3">
              <DialerHeader />
            </div>
          ) : null}
        </div>
        {/* Logo Mobile */}
        <div className="inline-block md:hidden p-2 my-2 -translate-x-[20%]">
          <Link to="/">
            <img src={new_logo} alt="Callrings CRM" className="w-[80px]" />
          </Link>
        </div>
        <button
          className="bg-[#231F20] sm:block md:hidden cursor-pointer p-1 text-center text-white leading-[16px]"
          onClick={ToggleClass}
        >
          {isActive ? <FaTimes size={16} /> : <FaSearch size={16} />}
        </button>
        <div
          className={`${
            isActive ? "flex flex-col gap-3" : "hidden"
          } md:flex md:flex-row !bg-[#f7f7f7] item-center basis-full md:basis-auto justify-end p-2.5`}
        >
          <div className="flex flex-row border-1 border-gray-300 rounded-[0.2rem] md:border-none md:p-0">
            <div className="relative w-ful">
              <input
                style={style}
                type="text"
                className="relative flex-1 w-full md:w-1/ md:!mr-2 max-h-[38px] border text-gray-900 text-sm md:bg-white border-gray-300 md:rounded-[0.2rem] p-2.5"
                placeholder="Search"
                value={searchJobs.searchValue}
                onChange={(e) => {
                  setSearchJobs({
                    ...searchJobs,
                    searchValue: e.target.value,
                  });
                }}
                onKeyDown={handleKeyDown}
              />
              {searchJobs.searchValue ? (
                <button
                  className="absolute right-1 top-[25%]"
                  onClick={() => {
                    setSearchJobs({
                      ...searchJobs,
                      searchValue: "",
                    });
                    if (location.pathname === "/leads/list") {
                      navigate(`/leads/list`);
                    } else if (location.pathname === "/deals/list") {
                      navigate(`/deals/list`);
                    }
                  }}
                >
                  <RxCross2 />
                </button>
              ) : null}
            </div>

            <Button
              text={
                isLoader ? (
                  <Loader size={5} width={20} height={20} color="#fff" />
                ) : (
                  <FaSearch size={20} />
                )
              }
              variant="btn_submit"
              className="max-h-[38px] !bg-[#042A42]"
              onClick={searchVerifiedJob}
            />
          </div>

          {/* <div className="sm:block md:block cursor-pointer justify-center items-center py-2 px-4">
            <p className="">
              {" "}
              Status: {isConnected ? "🟢 Connected" : "🔴 Offline"}
            </p>
          </div> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
