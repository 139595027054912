import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createUser,
  deleteUser,
  getCRMVendorsDrd,
  getTech,
  getUsers,
  getUsersDrd,
  getVendorsForJobs,
  getVerifiedUsersDrd,
  updateUser,
  updateUserPassword,
} from "../services/userService";
import { getFullName } from "../util/common";

const initialState = {
  isError: false,
  isLoading: false,
  loader: { getTech: false, getUserDrd: false },
  users: [],
  dialerUsers: [],
  roles: [],
  techs: [],
  message: "",
  isLoader: false,
  usersDrd: [],
  CRMVendorsDrd: [],
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Users Data
export const get_users = createAsyncThunk(
  "get_users",
  async (data, thunkAPI) => {
    let payload = { ...data };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    try {
      return await getUsers(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_dialer_users = createAsyncThunk(
  "get_dialer_users",
  async (data, thunkAPI) => {
    try {
      return await getUsers({
        page: 1,
        size: 1000,
      });
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_crm_vendors_drd = createAsyncThunk(
  "get_crm_vendors_drd",
  async (data, thunkAPI) => {
    try {
      return await getCRMVendorsDrd(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Users Drd
export const get_users_drd = createAsyncThunk(
  "get_users_drd",
  async (data, thunkAPI) => {
    try {
      return await getUsersDrd(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_verified_crm_users_drd = createAsyncThunk(
  "get_verified_crm_users_drd",
  async (thunkAPI) => {
    try {
      return await getVerifiedUsersDrd();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Technician Data
export const get_tech = createAsyncThunk("get_tech", async (data, thunkAPI) => {
  try {
    return await getTech(data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const get_vendors_for_jobs = createAsyncThunk(
  "get_vendors_for_jobs",
  async (data, thunkAPI) => {
    try {
      return await getVendorsForJobs(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create New User
export const create_user = createAsyncThunk(
  "create_user",
  async (data, thunkAPI) => {
    try {
      return await createUser(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update User
export const update_user = createAsyncThunk(
  "update_user",
  async ({ id, data }, thunkAPI) => {
    try {
      return await updateUser(id, data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update Password
export const update_password = createAsyncThunk(
  "update_password",
  async (data, thunkAPI) => {
    try {
      return await updateUserPassword(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Delete User
export const delete_user = createAsyncThunk(
  "delete_user",
  async (id, thunkAPI) => {
    try {
      return await deleteUser(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.users = [];
      state.message = "";
    },
    get_password: (state, action) => {
      state.users = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_users.pending, (state) => {
        state.isLoading = true;
        state.isLoader = true;
      })
      .addCase(get_users.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.users = action.payload?.data;
      })
      .addCase(get_users.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.users = [];
      })
      .addCase(create_user.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_user.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users.users.push(action.payload.data);
      })
      .addCase(create_user.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_user.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_user.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.users.users.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.users.users[result] = {
          ...state.users.users[result],
          ...action.payload.data,
          permission_roles: action?.payload?.data?.permission_roles?.map(
            ({ _id }) => _id
          ),
        };
      })
      .addCase(update_user.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_user.fulfilled, (state, action) => {
        const result = state.users.users.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.users.users[result].active = false;
      })
      .addCase(get_tech.pending, (state) => {
        state.loader.getTech = true;
        state.isLoader = true;
        state.techs = [];
      })
      .addCase(get_tech.fulfilled, (state, action) => {
        state.loader.getTech = false;
        state.isLoader = false;
        state.techs = action.payload?.data;
      })
      .addCase(get_tech.rejected, (state, action) => {
        state.loader.getTech = false;
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.techs = [];
      })
      .addCase(get_users_drd.pending, (state) => {
        state.loader.getUserDrd = true;
        state.usersDrd = [];
      })
      .addCase(get_users_drd.fulfilled, (state, action) => {
        state.loader.getUserDrd = false;
        const sortedUsers = [...action.payload?.data].sort((a, b) =>
          a?.first_name?.localeCompare(b?.first_name)
        );
        state.usersDrd = sortedUsers;
      })
      .addCase(get_users_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.loader.getUserDrd = false;
        state.isError = true;
        state.message = action.payload;
        state.usersDrd = [];
      })
      .addCase(get_dialer_users.pending, (state) => {
        state.loader.getUserDrd = true;
        state.dialerUsers = [];
      })
      .addCase(get_dialer_users.fulfilled, (state, action) => {
        state.isLoading = false;
        const users = action.payload?.data?.users?.filter(
          ({ is_dialer_user }) => !!is_dialer_user
        );
        const sortedUsers = [...users].sort((a, b) =>
          a?.first_name?.localeCompare(b?.first_name)
        );
        state.dialerUsers = sortedUsers?.map(
          ({ dialer_user, first_name = "", last_name = "" }) => ({
            value: dialer_user?.dialer_user,
            label: `${first_name} ${last_name}`,
            dialer_phone: dialer_user?.dialer_phone,
          })
        );
      })
      .addCase(get_dialer_users.rejected, (state, action) => {
        state.isLoading = false;
        state.loader.getUserDrd = false;
        state.isError = true;
        state.message = action.payload;
        state.dialerUsers = [];
      })
      .addCase(get_crm_vendors_drd.pending, (state) => {
        state.isLoading = true;
        state.isLoader = true;
      })
      .addCase(get_crm_vendors_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        const sortedVendors = [...action.payload?.data].sort((a, b) =>
          a?.first_name?.localeCompare(b?.first_name)
        );
        state.CRMVendorsDrd = sortedVendors;
      })
      .addCase(get_crm_vendors_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.CRMVendorsDrd = [];
      })
      .addCase(get_vendors_for_jobs.pending, (state) => {
        state.loader.getTech = true;
        state.isLoader = true;
        state.techs = [];
      })
      .addCase(get_vendors_for_jobs.fulfilled, (state, action) => {
        state.loader.getTech = false;
        state.isLoader = false;
        state.techs = action.payload?.data;
      })
      .addCase(get_vendors_for_jobs.rejected, (state, action) => {
        state.loader.getTech = false;
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.techs = [];
      })
      .addCase(get_verified_crm_users_drd.pending, (state) => {
        state.loader.getUserDrd = true;
        state.verifiedUsersDrd = [];
      })
      .addCase(get_verified_crm_users_drd.fulfilled, (state, action) => {
        state.loader.getUserDrd = false;
        state.verifiedUsersDrd = action.payload?.data
          ?.sort((a, b) => a.first_name.localeCompare(b.first_name))
          ?.map((item) => ({
            ...item,
            agent_id: item.verifiedMetaData?.agent_id,
            fullName: getFullName(item),
          }));
      })
      .addCase(get_verified_crm_users_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.loader.getUserDrd = false;
        state.isError = true;
        state.message = action.payload;
        state.usersDrd = [];
      })
      .addCase(update_password.pending, (state) => {
        state.isLoading = true;
        state.isLoader = true;
      })
      .addCase(update_password.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
      })
      .addCase(update_password.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, get_password } = usersSlice.actions;
export default usersSlice.reducer;
