import React, { useState, useEffect, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../atoms/Loader";
import {
  setNewMessCurrentPage,
  get_message_users_list,
} from "../../../features/smsCenterSlice";
import { throttle } from "lodash";

function MsgContacts(props) {
  const {
    userId,
    setUserId,
    newMessArray,
    type,
    setChatArray,
    setIsLoadChat,
    setIsChatEnd,
    setUserPhone,
    setIsEnd,
    isEnd,
    setNewMessArray,
    isMarkasRead,
    selectedRole,
  } = props;
  const dispatch = useDispatch();
  const [filterText, setFilterText] = useState("");
  const [isLoader, setIsLoading] = useState(false);
  const [isManualTrigger, setIsManualTrigger] = useState(false);
  const { isLoading, currentNewMessPage, messUsersList } = useSelector(
    (state) => state.smsCenter
  );
  const users = newMessArray?.filter(
    (record) =>
      record?.username?.toLowerCase().includes(filterText.toLowerCase()) ||
      record?.first_name?.toLowerCase().includes(filterText.toLowerCase()) ||
      record?.last_name?.toLowerCase().includes(filterText.toLowerCase()) ||
      record?.name?.toLowerCase().includes(filterText.toLowerCase())
  );
  const containerRefNew = useRef();
  const lastItemRef = useRef(null);

  useEffect(() => {
    if (lastItemRef.current && isManualTrigger) {
      lastItemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [newMessArray, isManualTrigger]);

  useEffect(() => {
    const container = containerRefNew.current;
    container.scrollTop = 0;
  }, [type]);

  const handleLoadMore = async () => {
    if (isLoader || isEnd) return;
    setIsLoading(true);

    try {
      const res = await dispatch(
        get_message_users_list({
          page: currentNewMessPage,
          user_type: type,
          role_id: selectedRole,
        })
      );

      if (res?.payload?.status === 200) {
        const data = res?.payload?.data?.data;
        if (data?.length) {
          setNewMessArray((prevData) => [...prevData, ...data]);
          await dispatch(setNewMessCurrentPage(currentNewMessPage + 1));
        } else {
          setIsEnd(true);
        }
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      setIsLoading(false);
      setIsManualTrigger(false);
    }
  };

  const handleScroll = () => {
    const container = containerRefNew.current;

    if (
      container.scrollTop + container.clientHeight >=
        container.scrollHeight - 50 &&
      !isEnd &&
      !isLoader
    ) {
      handleLoadMore();
    }
  };

  useEffect(() => {
    const container = containerRefNew.current;
    const throttledScrollHandler = throttle(handleScroll, 200);

    container.addEventListener("scroll", throttledScrollHandler);

    return () => {
      container.removeEventListener("scroll", throttledScrollHandler);
    };
    // eslint-disable-next-line
  }, [currentNewMessPage, isEnd, isLoader]);

  const handleLinkClick = () => {
    setIsManualTrigger(true);
    handleLoadMore();
  };

  return (
    <>
      <div className="relative w-full px-3.7 !my-2">
        <input
          className={
            "shadow appearance-none border rounded w-full !py-2 !pr-3 pl-12 text-gray-700 leading-tight focus:outline-none focus:shadow-outline-sm !pl-15 h-[31px]"
          }
          style={{ minHeight: 31 }}
          placeholder="Search"
          value={filterText}
          onChange={(e) => {
            setFilterText(e.target.value);
          }}
        />
        <div className="absolute top-[3px] left-6 border-r border-[#dee2e6] pr-2.5 flex justify-center items-center h-[25px]">
          <FaSearch />
        </div>
      </div>
      <div
        className="overflow-y-auto h-[450px] scrollbar-custom"
        ref={containerRefNew}
      >
        {users?.map((data, index) => {
          let text = data?.username
            ? data?.username
                ?.split(" ")
                .slice(0, 2)
                .map((string) => (string ? string[0].toUpperCase() : ""))
            : data?.name
            ? data?.name
                ?.split(" ")
                .slice(0, 2)
                .map((string) => (string ? string[0].toUpperCase() : ""))
            : data?.first_name
                ?.split(" ")
                .slice(0, 2)
                .map((string) => (string ? string[0].toUpperCase() : ""));
          let phone = type === "Client" ? data?.home_phone : data?.phone;
          return (
            <div
              ref={index === newMessArray.length - 1 ? lastItemRef : null}
              className={`wms-hysearch-result border-bottom flex items-center ${
                userId?.user?._id === data?._id ||
                userId?.client?._id === data?._id
                  ? "bg-[#f7f8f8] border-l-4 border-menu"
                  : ""
              }`}
              onClick={() => {
                if (isLoader || isLoading || isMarkasRead) return;
                setChatArray([]);
                setUserId({
                  user_type: type,
                  user_phone: phone,
                  user_id: data?._id,
                });
                setUserPhone(phone);
                setIsLoadChat(false);
                setChatArray([]);
                setIsChatEnd(false);
              }}
              key={index}
            >
              <div className="wms-imgcollage">
                <div className="wms-lettercoll">
                  <span>{text}</span>
                </div>
              </div>
              <div className="flex flex-row justify-between !w-[100%]">
                <div className="wms-hysearch-result-name wms-truncate-ellipsis !w-[90%] text-sm">
                  {data?.first_name || data?.last_name
                    ? `${data?.first_name || ""} ${
                        data?.last_name || ""
                      }`.trim()
                    : data?.name || data?.phone}
                </div>
              </div>
              <span className="wms_menu_item_count" />
            </div>
          );
        })}
      </div>
      <span className="text-[#2d4665] text-sm flex items-center justify-between text-center w-full ml-2 border-t border-gray-300 pt-1 px-2 py-1 mb-2">
        {!isLoading && newMessArray.length && messUsersList?.totalItems > 0 ? (
          <>
            <span className="flex items-center text-[#2d4665]">
              Total messages:
              <span className="font-medium text-sm text-primary-100 ml-2 mt-1">
                {newMessArray.length} of {messUsersList?.totalItems}
              </span>
            </span>

            {!isEnd && newMessArray.length < messUsersList?.totalItems ? (
              <>
                <span className="mx-2 text-gray-500">|</span>
                <span
                  className="text-primary-100 text-sm cursor-pointer ml-4 hover:text-primary-100 mr-4"
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    handleLinkClick();
                  }}
                >
                  Load more...
                </span>
              </>
            ) : null}
          </>
        ) : (
          <span className="mb-4 flex justify-center items-center w-full">
            {isLoading ? <Loader linear="linear" /> : null}
          </span>
        )}
      </span>
    </>
  );
}

export default MsgContacts;
