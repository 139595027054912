import api from "./api";

export const createSMSTemplate = async (data) => {
  return await api.post("/api/sms_templates", data);
};

export const getSMSTemplates = async () => {
  return await api.get("/api/sms_templates");
};

export const updateSMSTemplate = async (data) => {
  return await api.put("/api/sms_templates", data);
};

export const deleteSMSTemplate = async (id) => {
  return await api.delete("/api/sms_templates", { data: { id } });
};
