import api from "./api";

export const getLeadSourceType = async () => {
  return await api.get(`/api/lead_source/lead_type`);
};
export const createLeadSourceType = async (data) => {
  return await api.post("/api/lead_source/lead_type", data);
};
export const updateLeadSourceType = async ({ _id, name }) => {
  return await api.put(`/api/lead_source/lead_type/${_id}`, { name });
};
export const deleteLeadSourceType = async (id) => {
  return await api.delete(`/api/lead_source/lead_type/${id}`);
};
