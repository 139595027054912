import api from "./api";

export const getLeadSource = async (data) => {
  return await api.post(`/api/lead_source/report`, data);
};
export const getLeadSourceDrd = async (data) => {
  return await api.get(`/api/lead_source/drd`);
};
export const createLeadSource = async (data) => {
  return await api.post("/api/lead_source", data);
};
export const updateLeadSource = async (data) => {
  const values = { ...data };
  delete values?._id;
  return await api.put(`/api/lead_source/${data?._id}`, values);
};
export const deleteLeadSource = async (id) => {
  return await api.delete(`/api/lead_source/${id}`);
};

export const getLeadSourceAccounts = async (data) => {
  return await api.get(`/api/lead_source/account/${data}`);
};
export const createLeadSourceAccounts = async (data) => {
  return await api.post("/api/lead_source/account", data);
};
export const updateLeadSourceAccounts = async (data) => {
  let payload = { ...data };
  delete payload._id;
  return await api.put(`/api/lead_source/account/${data?._id}`, payload);
};
export const deleteLeadSourceAccounts = async (id) => {
  return await api.delete(`/api/lead_source/account/${id}`);
};
