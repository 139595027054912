import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createDistributionCategory,
  deleteDistributionCategory,
  getDistributionCategory,
  updateDistributionCategory,
} from "../services/distributionCategoryService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Distribution Category
export const get_distribution_category = createAsyncThunk(
  "get_distribution_category",
  async (data, thunkAPI) => {
    try {
      return await getDistributionCategory(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create New Distribution Category
export const create_distribution_category = createAsyncThunk(
  "create_distribution_category",
  async (data, thunkAPI) => {
    try {
      return await createDistributionCategory(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Distribution Category
export const update_distribution_category = createAsyncThunk(
  "update_distribution_category",
  async (data, thunkAPI) => {
    try {
      return await updateDistributionCategory(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Distribution Category
export const delete_distribution_category = createAsyncThunk(
  "delete_distribution_category",
  async (id, thunkAPI) => {
    try {
      return await deleteDistributionCategory(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const distributionCategorySlice = createSlice({
  name: "distributionCategory",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_distribution_category.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_distribution_category.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_distribution_category.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
      })
      .addCase(create_distribution_category.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_distribution_category.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.push(action.payload.data);
      })
      .addCase(create_distribution_category.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_distribution_category.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_distribution_category.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data,
        };
      })
      .addCase(update_distribution_category.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_distribution_category.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_distribution_category.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_distribution_category.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { reset } = distributionCategorySlice.actions;
export default distributionCategorySlice.reducer;
