import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import Dropdown from "./Dropdown";
import React from "react";
import { FaAngleDown } from "react-icons/fa";
import { new_logo, verified_crm_logo } from "../../../images";
import { toast } from "react-toastify";
import { verifiedMenu } from "./menu.config";

const TopMenu = ({ show_navlabel, isDroping }) => {
  const { status, isJobDone } = useSelector((state) => state.dialer);
  const user = JSON.parse(localStorage.getItem("user"));
  const dropdownRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const showError = () => {
    if (status === "INCALL" || status === "DEAD") {
      toast.info("Please hangup your current call before you proceed", {
        position: "top-center",
      });
    } else if (status === "DISPO") {
      toast.error("Please select a disposition...", {
        position: "top-center",
      });
    }
  };
  const isAllow = (item) => {
    if (user?.menu_permissions?.length > 0 && user?.role) {
      if (
        user?.menu_permissions?.some((obj) => obj === item?.label) ||
        user?.company_name === "Verified CRM"
      ) {
        return true;
      }
      return false;
    }
  };
  const location = useLocation();
  const [clickedOn, setClickedOn] = React.useState("");
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setClickedOn("");
    }
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setClickedOn("");
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const disbale =
    status && status !== "PAUSE" && status !== "WAIT" && !isJobDone;
  return (
    <>
      <div className="top-menu w-full hidden md:flex items-center gap- items-center">
        <Link to="/" className="p-2">
          {user?.company_name && user.company_name === "Verified CRM" ? (
            <img
              src={verified_crm_logo}
              alt="Verified CRM"
              style={{ width: "140px" }}
            />
          ) : (
            <img
              src={verified_crm_logo}
              alt="Callrings CRM"
              style={{ width: "140px" }}
            />
          )}
        </Link>
        <div className="flex gap-1" ref={dropdownRef}>
          {verifiedMenu.map((item, key) => {
            const { label, to, Icon, children, onClick } = item;
            return isAllow(item) ? (
              <div className="" key={key}>
                {item?.children ? (
                  <>
                    <div
                      className="transition-all gap-x-1 border page_link group hover:text-[#828282] hover:no-underline text-[#828282]  flex items-center justify-between py-[3px] !px-1 w-full outline-none rounded cursor-pointer relative "
                      onClick={() => {
                        setClickedOn(clickedOn === to ? "" : to);
                        setIsOpen(!isOpen);
                      }}
                    >
                      <Icon className="icons group-hover:text-primary-100" />
                      <div className="flex items-center">{label}</div>
                      <FaAngleDown />
                    </div>
                    {clickedOn === to && !isDroping && !disbale && (
                      <Dropdown
                        show_navlabel={show_navlabel}
                        label={label}
                        to={to}
                        Icon={Icon}
                        options={children}
                        key={key}
                        isDroping={isDroping}
                        className="absolute !w-[250px] bg-white shadow-sm top-16"
                        subMenuClass="!pl-5"
                      />
                    )}
                  </>
                ) : (
                  <NavLink
                    title={label}
                    to={disbale ? location?.pathname : to}
                    onClick={disbale ? showError : onClick}
                    className="page_link gap-x-1 border group hover:text-[#828282] hover:no-underline no-underline text-[#828282] flex items-center py-[3px] !px-5 w-full outline-none rounded cursor-pointer transition-all relative"
                  >
                    <Icon className="icons group-hover:text-primary-100" />
                    {label}
                  </NavLink>
                )}
              </div>
            ) : null;
          })}
        </div>
      </div>
    </>
  );
};

export default TopMenu;
