import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getWebforms } from "../services/webFormsService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  selectedLead: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Lead Source
export const get_webforms = createAsyncThunk(
  "get_webforms",
  async (data, thunkAPI) => {
    try {
      return await getWebforms(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const webFormsSlice = createSlice({
  name: "webForms",
  initialState,
  reducers: {
    webFormsReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
    selectedLead: (state, action) => {
      state.selectedLead = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_webforms.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(get_webforms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_webforms.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { webFormsReset, selectedLead } = webFormsSlice.actions;
export default webFormsSlice.reducer;
