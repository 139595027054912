/**
 * Filter and prepare user data according to schema requirements
 * @param {Object} data - The input data object
 * @param {string} type - The type of operation ('create' or 'update')
 * @returns {Object} Filtered data object
 */
export const prepareUserData = (data, type = "create") => {
  // Base fields for both create and update
  const baseFields = {
    first_name: data.first_name || "",
    last_name: data.last_name || "",
    phone: data.phone || "",
    fax: data.fax || "",
    email: data.email || "",
    role_id: data.role_id || null,
    is_dialer_user: data.is_dialer_user || false,
    call_center_role: data.call_center_role || null,
    closer_campaigns: data.closer_campaigns || [],
    dialer_user_did: data.dialer_user_did || "",
    call_monitor_permissions: {
      barge: data.call_monitor_permissions?.barge || false,
      monitor: data.call_monitor_permissions?.monitor || false,
      whisper: data.call_monitor_permissions?.whisper || false,
    },
  };

  // Additional fields for create operation
  const createOnlyFields =
    type === "create"
      ? {
          username: data.username || "",
        }
      : {};

  return {
    ...baseFields,
    ...createOnlyFields,
  };
};

/**
 * Validate required fields for user data
 * @param {Object} data - The user data to validate
 * @param {string} type - The type of operation ('create' or 'update')
 * @returns {Object} Validation result { isValid: boolean, errors: Array }
 */
export const validateUserData = (data, type = "create") => {
  const errors = [];

  // Required fields validation
  if (
    !data.first_name ||
    data.first_name.length < 2 ||
    data.first_name.length > 100
  ) {
    errors.push({
      first_name:
        "First name is required and must be between 2 and 100 characters",
    });
  }

  if (
    data.last_name &&
    (data.last_name.length < 2 || data.last_name.length > 100)
  ) {
    errors.push({
      last_name: "Last name must be between 2 and 100 characters",
    });
  }

  // Phone validation
  if (data.phone && !/^1\d{10}$/.test(data.phone)) {
    errors.push({
      phone: "Phone number must be 11 digits starting with 1",
    });
  }

  // // Email validation
  // if (data.email) {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!emailRegex.test(data.email)) {
  //     errors.push({
  //       email: "Please enter a valid email",
  //     });
  //   }
  // }

  // Role validation
  if (!data.role_id) {
    errors.push({
      role_id: "Role is required",
    });
  }

  // Username validation for create operation
  if (type === "create") {
    if (
      !data.username ||
      data.username.length < 5 ||
      data.username.length > 40
    ) {
      errors.push({
        username:
          "Username is required and must be between 5 and 40 characters",
      });
    } else if (!/^[a-zA-Z0-9]+$/.test(data.username)) {
      errors.push({
        username: "Username must be alphanumeric",
      });
    }
  }

  // Dialer user validation
  if (data.is_dialer_user === true) {
    if (!data.call_center_role) {
      errors.push({
        call_center_role: "Call center role is required",
      });
    }
  }

  // DID validation
  if (data.dialer_user_did && !/^1\d{10}$/.test(data.dialer_user_did)) {
    errors.push({
      dialer_user_did:
        "DID must be exactly 11 numeric characters starting from 1",
    });
  }
  // Email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
  if (!data.email) {
    errors.push({
      email: "Email is required.",
    });
  } else if (!emailRegex.test(data.email)) {
    errors.push({
      email: "Email is not valid.",
    });
  }

  return {
    isValid: errors.length === 0,
    errors,
  };
};
