import dayjs from "dayjs";
import userPermission from "./userPermission";

export const getFullName = (record) => {
  const fullName = `${record?.first_name || ""} ${record?.last_name || ""}`;
  if (fullName && fullName !== " ") {
    return fullName;
  } else {
    return "";
  }
};

export const getFormattedDate = (date, format) => {
  if (!date) return "";
  return dayjs(date).format(format ? format : "ddd, MMM D, YYYY h:mm:ss A");
};

export const getFormattedAddress = (shipping_address) => {
  const { address, city, state, zip } = shipping_address || {};
  const addressComponents = [address, city, state, zip].filter(Boolean);
  return addressComponents.join(", ");
};

export const maskPhoneNumber = (phoneNumber) => {
  if (
    userPermission("Show Phone Number") ||
    userPermission("Change Client Phone Number") ||
    !phoneNumber
  )
    return phoneNumber;
  const strPhoneNumber = phoneNumber.toString();
  return "******" + strPhoneNumber.slice(-4);
};

export const removeLeadingOne = (input) => {
  if (!input) return null;
  let str = input.toString();
  if (str.startsWith("+1")) {
    str = str.slice(2);
  } else if (str.startsWith("1")) {
    str = str.slice(1);
  }
  return str;
};

export const filterModelRules = (filterModel, ruless) => {
  filterModel?.items?.map((rule) => {
    ruless = [
      ...ruless,
      {
        field: `${rule.field}`,
        op:
          rule.operator === "contains"
            ? "cn"
            : rule.operator === "equals"
            ? "eq"
            : rule.operator === "is"
            ? "eq"
            : rule.operator === "="
            ? "eq"
            : rule.operator === "!="
            ? "not"
            : rule.operator === ">"
            ? "gt"
            : rule.operator === ">="
            ? "gte"
            : rule.operator === "<"
            ? "lt"
            : rule.operator === "<="
            ? "lte"
            : rule.operator === "onOrBefore"
            ? "lte"
            : rule.operator === "before"
            ? "lt"
            : rule.operator === "onOrAfter"
            ? "gte"
            : rule.operator === "after"
            ? "gt"
            : rule.field === "deleted" || rule.field === "active"
            ? "is"
            : rule.operator === "true"
            ? "eq"
            : rule.operator === "false"
            ? "eq"
            : rule.operator,
        data:
          rule.field === "latest_position" || rule.field === "detail.sellPrice"
            ? +rule.value
            : rule.value === false
            ? rule.value
            : rule.operator === "false"
            ? false
            : rule.operator === "true"
            ? true
            : rule.field === "deleted" || rule.field === "active"
            ? rule.operator
            : rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
      },
    ];
  });
  return ruless;
};

export const truncateText = (text, char) => {
  if (!text) return text;
  return text.length > char ? text.substring(0, char) + "..." : text;
};

export const formatPhoneNumbers = (number) => {
  if (number && number.length === 10) {
    const areaCode = number.slice(0, 3);
    const middlePart = number.slice(3, 6);
    const lastPart = number.slice(6);
    return `(${areaCode}) ${middlePart}-${lastPart}`;
  } else {
    return number;
  }
};