import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createLeadSource,
  createLeadSourceAccounts,
  deleteLeadSource,
  deleteLeadSourceAccounts,
  getLeadSource,
  getLeadSourceAccounts,
  getLeadSourceDrd,
  updateLeadSource,
  updateLeadSourceAccounts,
} from "../services/leadSourceService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  accounts: null,
  leadSourceDrd: [],
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Lead Source
export const get_lead_source = createAsyncThunk(
  "get_lead_source",
  async (data, thunkAPI) => {
    try {
      return await getLeadSource(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Lead Source
export const get_lead_source_drd = createAsyncThunk(
  "get_lead_source_drd",
  async (data, thunkAPI) => {
    try {
      return await getLeadSourceDrd(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create New Lead Source
export const create_lead_source = createAsyncThunk(
  "create_lead_source",
  async (data, thunkAPI) => {
    try {
      return await createLeadSource(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Lead Source
export const update_lead_source = createAsyncThunk(
  "update_lead_source",
  async (data, thunkAPI) => {
    try {
      return await updateLeadSource(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Lead Source
export const delete_lead_source = createAsyncThunk(
  "delete_lead_source",
  async (id, thunkAPI) => {
    try {
      return await deleteLeadSource(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_lead_source_accounts = createAsyncThunk(
  "get_lead_source_accounts",
  async (data, thunkAPI) => {
    try {
      return await getLeadSourceAccounts(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const create_lead_source_accounts = createAsyncThunk(
  "create_lead_source_accounts",
  async (data, thunkAPI) => {
    try {
      return await createLeadSourceAccounts(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Lead Source
export const update_lead_source_accounts = createAsyncThunk(
  "update_lead_source_accounts",
  async (data, thunkAPI) => {
    try {
      return await updateLeadSourceAccounts(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Lead Source
export const delete_lead_source_accounts = createAsyncThunk(
  "delete_lead_source_accounts",
  async (id, thunkAPI) => {
    try {
      return await deleteLeadSourceAccounts(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const leadSourceSlice = createSlice({
  name: "lead_source",
  initialState,
  reducers: {
    leadSourceReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_lead_source.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(get_lead_source.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_lead_source.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_lead_source_drd.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(get_lead_source_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.leadSourceDrd = action.payload?.data;
      })
      .addCase(get_lead_source_drd.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_lead_source.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(create_lead_source.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_lead_source.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_lead_source.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(update_lead_source.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_lead_source.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_lead_source.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(delete_lead_source.fulfilled, (state, action) => {
        state.record.records = state.record?.records?.filter(
          ({ _id }) => _id !== action?.payload?.data.data._id
        );
        state.record.totalItems = state.record.totalItems - 1;
        state.isLoading = false;
      })
      .addCase(delete_lead_source.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_lead_source_accounts.pending, (state, action) => {
        state.isLoading = true;
        state.accounts = null;
      })
      .addCase(get_lead_source_accounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.accounts = action.payload?.data;
      })
      .addCase(get_lead_source_accounts.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_lead_source_accounts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(create_lead_source_accounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.accounts = action.payload.data;
      })
      .addCase(create_lead_source_accounts.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_lead_source_accounts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(update_lead_source_accounts.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_lead_source_accounts.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_lead_source_accounts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(delete_lead_source_accounts.fulfilled, (state, action) => {
        state.record.records = state.record?.records?.filter(
          ({ _id }) => _id !== action?.payload?.data.data._id
        );
        state.record.totalItems = state.record.totalItems - 1;
        state.isLoading = false;
      })
      .addCase(delete_lead_source_accounts.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { leadSourceReset } = leadSourceSlice.actions;
export default leadSourceSlice.reducer;
